.buyedbookmain{
display: flex;
justify-content: center;
align-items: center;
gap: 30px;
flex-direction: column;
background-color: #FAFAFA;
}

.buyedbooknav{
width: 100%;
}

.buybookcontainer{
width: 90%;
display: flex;
flex-direction: column;
gap: 30px;
}

.buybooktitlediv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.bookscontainermaindive{
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.bookmaindivfrontend{
display: flex;
flex-direction: column;
gap: 15px;
justify-content: end;
width: 350px;
border: 1px solid var(--purplecolor);
border-radius: 21px;
padding-bottom: 20px;
font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}

.imagedivbook{
    width: 100%;
    height: 262px;
}

.imagedivbook img{
    width: 100%;
    height: 262px;
    border-radius: 26px 26px 0px 0px;
}

.bookmaindivfrontend div:nth-of-type(3){
    font-size: 14px;
    font-weight: 600;
    text-align: end;
    padding-inline: 5px;
}

.bookmaindivfrontend div:nth-of-type(2){
    font-size: 16px;
    color: var(--purplecolor);
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.bookmaindivfrontend div:nth-of-type(4){
    font-size: 14px;
    font-weight: 600;
    text-align: end;
    padding-inline: 5px;
}

.buttondivbookbuyed{
width: 100%;
text-align: center;
}

.buttondivbookbuyed button{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
}

.buyedbookfooter{
width: 100%;
}

@media(max-width:430px){
    .bookmaindivfrontend{
        width: 320px;
    }

    .imagedivbook {
        width: 100%;
        height: 240px;
    }

    .imagedivbook img {
        width: 100%;
        height: 240px;
    }
}