.a-d-details{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #B571AC;
    color: white;
    padding: 10px;
    text-align: right;
    font-size: 15px;
    gap: 8px;
    border-radius: 5px;
   width: 350px;
    margin-inline: 10px;
}
.a-d-details2{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #B571AC;
  color: white;
  padding: 10px;
  text-align: right;
  font-size: 15px;
  gap: 8px;
  border-radius: 5px;
 width: 100%;
  margin-inline: 10px;
}
.a-d-details1{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #E51717;
    color: white;
    padding: 10px;
    text-align: right;
    font-size: 15px;
    gap: 8px;
    border-radius: 5px;
    max-width: 350px;
    margin-inline: 10px;
}
.a-d-video{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin-inline: 10px;
    border-radius: 10px 10px 10px 10px;
}
.a-d-video1{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline: 10px;
  border-radius: 10px 10px 10px 10px;
}
.a-d-v-img{
    max-width: 350px;
}
.a-d-v-img img{
    max-width: 350px;
    border-radius: 10px 10px 0px 0px;
}
.added-details{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.a-d-v-details{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
    gap: 5px;
    background-color: white;
    padding: 8px;
}
.a-d-v-d-title{
    font-size: 16px;
    font-weight: 500;
    direction: rtl;
}
.a-d-v-d-description{
    overflow: auto;
    font-size: 13px;
    font-weight: 300;
    direction: rtl;
    height: 70px;
    line-height: 1.5em;
    overflow: -moz-scrollbars-none; /* for Firefox */
    -ms-overflow-style: none;  /* for Internet Explorer and Edge */
    scrollbar-width: none; /* for Firefox */
} 
  .a-d-v-d-description::-webkit-scrollbar { /* for Chrome, Safari, and Opera */
    display: none;
  }
  .added-video-elements{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .whole-added{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .added-video-elements-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .whole-added{
    padding: 10px;
  }
  .d-c-deleted-comment{
    width: 350px;
    padding: 20px;
    background-color: white;
    margin-inline: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
  }
  .d-c-d-c-profile{
    display: flex;
    gap: 10px;
    align-items: center;
    gap: 15px;
  }
  .d-c-d-c-p-name{
    font-size: 15px;
    font-size: 13px;
  }
  .d-c-d-c-p-img img{
    width: 30px;
    height: 30px;
    border: 1px solid #B571AC;
    border-radius: 50%;
  }
  .d-c-d-c-p-comment{
    margin-right: 30px;
    font-size: 13px;
  }
  .d-c-d-c-p-likes-container{
    display: flex;
    margin-right: 30px;
    gap: 5px;
    padding: 5px;
    border-radius: 23px;
    background-color: #D9D9D94D;
    font-size: 14px;
    color: #B571AC;
  }