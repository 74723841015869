.allusers-container{
    background-color: whitesmoke;
}
.au-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 15px;
    gap: 15px;
    background-color: whitesmoke;
    min-height: 55vh;
}
.au-heading{
    font-size: 18px;
    font-weight: 600;
    color: #B571AC;
    width: 95%;
    text-align: right;
}
.au-search-container{
    width: 95%;
}
.au-search-cont2{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 5px;
    border: 1px solid #B571AC;
    border-radius: 5px;
    background-color: white;
    font-size: 17px;
    gap: 4px;
}
.au-s-input{
    width: 100%;
}
.au-s-input input{
    border: none;
    outline: none;
    width: 100%;
    text-align: right;
    direction: rtl;
}
.au-users-container{
    width: 95%;
}
.au-u-cont2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 10px;
    gap: 10px;
}
.au-u-box{
    display: flex;
    justify-content: end;
    gap: 8px;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    border-radius: 10px;
}
.au-u-box:hover{
    background-color: white;
}
.au-u-data{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
    font-size: 15px;
}
.au-u-b-name{
    font-weight: 600;
}
.au-u-b-img img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}