.statusSideBar{
    background-color: #B571AC;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    color: white;
    align-items: center;
    width: 30vh;
    height: 100vh;
}
.statusSideBar-Heading{
    font-size: 15px;
}
.status-box-profile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25vh;
}
.status-box-profile-profile{
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 20px;
}
.status-box-profile-profile img{
    background-color: white;
    border-radius: 50%;
    border: 1px solid white;
    width: 5vh;
    height: 5vh;
}
.status-manager-box{
    width: 100%;
    height: 100%;
}
.status-box-profile-status img{
    width: 2vh;
    height: 2vh;
}
.status-box-data{
    display: flex;
    font-size: 10px;
    justify-content: space-between;
}
.statusSideBar-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.status-manager-box-line{
    width: 100%;
    border-bottom: 1px solid white;
}
.heading-in-usersidebar{
    font-size: 20px;
}