.subscribe-container{
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
}
.subcribed-info{
    color: white;
    padding: 10px 20px;
    background-color: #B571AC;
    border: none;
    border-radius: 8px;
    font-size: 15px;
}