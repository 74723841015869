.webinar-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: whitesmoke;
    padding-inline: 10px;text-align: center;
}
.w-head-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    text-align: center;
}
.w-h-c-text{
    font-weight: 500;
    font-size: 16px;
    color:#B571AC;
}
.w-h-c-head{
    font-weight: 600;
}
.w-upper-head{
    font-size: 18px;
    color: #B571AC;
}
.w-upper-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 15px;
    text-align: right;
}
.date-component{
    width: 100%;
}
.w-middle-container{
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
}
.w-middle-flex{
    display: flex;
    padding-top: 15px;
    flex-direction: row-reverse;
    gap: 20px;
}
.w-middle-head{
    font-size: 18px;
    color: #B571AC;
    width: 70px;
}
.w-middle-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 15px;
    border-bottom: 2px solid #5d5d5d;
    text-align: right;
}
.w-lower-text{
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 4px;
    text-align: right;
}
.inputs-heading{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
}
.input-heading-green{
    color: #48B865;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 70px;
}
.w-body-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-inline: 20px;
    gap: 15px;
}
.w-b-c-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.w-b-c-flex{
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: center;
}
.w-b-c-b-part{
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    gap: 10px;
}
.w-b-c-b-part label{
    font-size: 14px;
    font-weight: 500;
}
.w-b-c-b-part input{
    width: 100%;
    border: 1px solid #B571AC;
    border-radius: 5px;
    padding: 6px;
    font-size: 13px;
    background-color: white;
    direction: rtl;
}
.w-b-c-b-partt{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
    gap: 10px;
}
.w-b-c-b-p-body-title{
    font-size: 14px;
    font-weight: 500;
}
.w-b-c-b-p-option{
    display: flex;
    gap: 10px;
}
.w-b-c-b-p-o-further{
    display: flex;
    gap: 4px;
}
.w-b-c-b-p-o-further label{
    font-size: 14px;
}
.last-text-area{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: end;
    gap: 12px;
}
.last-text-area label{
    font-size: 14px;
    font-weight: 500;
}
.last-text-area textarea{
    width: 100%;
    height: 100px;
    border: 1px solid #B571AC;
    border-radius: 5px;
    direction: rtl;
    background-color: white;
    resize: none;
    padding: 8px;
}
.last-ending-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 14px;
    font-size: 14px;
}
.l-e-b-btn{
    padding: 8px 15px;
    color: white;
    background-color: #B571AC;
    cursor: pointer;
    border-radius: 8px;
    border: none;
}