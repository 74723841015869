

.paymentmaindiv{

    padding-inline: 2rem;
    box-sizing: border-box;
    background-color: var(--backgroundcolor);

    padding-block: 4rem;
}


.paymentleftsideandheading{
    background-color: none;
    color: var(--blackcolor);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 1rem;

}

.leftarrowicon{
    font-size: 4rem;
    cursor: pointer;
}

.paymenttextdiv{
    margin: 0 auto;
}

.paymentleftsideandheading h1{
    font-family: "Cairo";
    font-weight: 700;
    font-size: 2.5rem;
}

.paymentcontent{
    background-color: #F1F1F1;
    text-align: end;
    margin-inline: 2rem;
    padding: 1rem;
}

.paymentcontent h1{
    font-family: "Cairo";
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 4rem;
}


.paymentcardmaindiv{
    display: flex;

    margin-inline: 2rem;
    gap: 2rem;
    margin-top: 5rem;
}

.cardfst{
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    border: 2px solid #E6E6E6;
    border-radius: 5px;
    width: 10%;
    margin-top: 2rem;
    padding: 1rem;

}

.cardfst p{
    font-family: "Cairo";
    font-weight: 500;
    font-size: 1.5rem;
}


.cardfst:hover{
    border: 2px solid #29292C;
    cursor: pointer;
    box-shadow: 0px 1.14px 1.14px 0px #00000014;

}

.allpaymentcontent{
    margin-inline: 2rem;
    padding-top: 2rem;

}

.allpaymentcontent p{
    font-family: "Cairo";
    font-weight: 400;
    font-size: 1.5rem;
    color: #30313D;
}


.allpaymentline{
    height: 2px;
    background-color: #E6E6E6;
    margin-block: 1rem;
}


.payment2andpmaindiv{
    display: flex;
    gap: 2rem;
}

.payment2andpmaindiv p{
    color: #6D6E78;
    font-family: "Cairo";
    font-weight: 400;
    font-size: 1.5rem;
}

.carddetaildiv{
    font-family: "Cairo";
    font-weight: 400;
    font-size: 1.6rem;
    color: #30313D;
    border: 1px solid #E6E6E6;
    width: 22%;
    display: flex;
    align-items: center;
    border-radius: 5px;
}


.payicon{
    width: 60px;
}

.carddetaildiv input{
    padding: 1rem;
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    outline: none;

}


.card2andp{
    display: flex;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    border-radius: 5px;
    width: 100%;

}

.expirationandcvcmainflexdiv{
    display: flex;
    gap: 2rem;
}

.expirationtext input{
    padding: 1.5rem;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}

.card2andp input{
    padding: 1.5rem;
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}


.countrytextdiv p {
    font-family: "Cairo";
    font-weight: 400;
    font-size: 1.6rem;
}


.countrytextdiv select{
    border: 1px solid #E6E6E6;
    box-shadow: 0px 1.14px 1.14px 0px #00000008;
    padding: 1rem;
    outline: none;
    border-radius: 5px;
    width: 100%;

}



.paybutton{
    text-align: center;
    margin-top: 2rem;
}

.paybutton button{
    background-color: var(--purplecolor);
    color: #fff;
    font-family: "Cairo";
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 1rem;
    cursor: pointer;
}

.cardfst.active {
    border: 2px solid #000; /* Example: add a border to highlight the active card */
}


@media screen and (max-width:500px) {
    .cardfst{
        width: 40%;
    }
    .paymentleftsideandheading h1{
        font-size: 16px;
    }
}

