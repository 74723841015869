.event-heading{
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #B571AC;
    border-top: 1px solid #B571AC;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: black;
}
.webinars-outer{
    padding: 20px 30px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}
.webinars-inner{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
}
.w-i-box{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 320px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #B571AC;
    padding-bottom: 6px;
    overflow: hidden;
}
.w-i-b-img img{
    width: 300px;
    height: 180px;
    object-fit: cover;
}
.w-i-title-things{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.w-i-tt-title{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.w-i-tt-time{
    padding: 4px 8px;
    background-color: #F9EAF7;
    border-radius: 23px;
    color: #B571AC;
    font-size: 13px;
}
.w-i-price{
    font-size: 15px;
    font-weight: 500;
}