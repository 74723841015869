


.splach_screenmaindiv{
    text-align: end;
    padding-inline: 3rem;
    padding-block: 3rem;
    background-color: var(--backgroundcolor);
}

.splach_screenmaindiv h1{
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.6rem;
    padding-top: 2rem;
    color: var(--purplecolor);
}
.splach_screenmaindiv p{
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.5rem;
    padding-top: 2rem;
}

.splach_screenmaindiv input{
/* padding: 1.4rem; */
height: 45px;
padding-right: 10px;
width: 100%;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
border: 1px solid #5D5D5D;
outline: none;
border-radius: 1.4rem;
direction: rtl;
margin-top: 1rem;
font-size: 16px;
background: rgba(245, 245, 245, 1);
}

.splach_screenmaindiv input::placeholder{
    font-family: "avenirarabicmedium";
    font-size: 14px;
}
.splach_screenmaindiv button{
    background-color: var(--purplecolor);
    color: #fff;
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.7rem;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 1.4rem;
}