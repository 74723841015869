.resetpaswardmain {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-family: "Cairo", sans-serif;
background-color: var(--backgroundcolor);
}

.resetpaswardnavbar {
    width: 100%;
}

.resetpaswardcontainer {
    height: 50vh;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.respaswardmain {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    box-shadow: 6px 6px 11px 3px #00000040;
    border-radius: 20px;
    height: 40vh;
    padding: 15px 10px;
    justify-content: center;
    align-items: end;
    font-size: 15px;
    font-weight: 600;
}

.respaswardmain div:nth-of-type(1) {
    /* font-family: "AvenirRegular";
     */
    font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;

}

.respaswardmain div:nth-of-type(2) {
    font-family: "AvenirRegular";
    font-weight: 400;   
}

.respaswardmain input {
    width: 100%;
    /* text-align: right; */
    height: 35px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #5D5D5D;
    outline: none;
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 16px;
}

.respaswardmain div:nth-of-type(4) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--purplecolor);
    color: white;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    /* font-family: "AvenirRegular"; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
    font-weight: 400;
}



.resetpaswardfooter {
    position: relative;
    width: 100%;
    bottom: 0px;
}




@media(max-width:430px) {
    .resetpaswardfooter {
        /* position: absolute; */
        width: 100%;
        bottom: 0px;
    }

    .resetpaswardcontainer {
        height: 68vh;
    }
}