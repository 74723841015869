.maindivofcollywobbles{
    /* height: 100vh; */
    padding-bottom: 30px;
    background-color: #FAFAFA;
}
.full-screen-image{
    position: fixed;  
  top: 20px; 
  font-size: 30px;
  right: 20px;  
  color: rgb(255, 0, 0);
  object-fit: contain;
  cursor: pointer; 
  z-index: 1000;
}

.imagePreviewContainer{
    display: flex;
    padding: 5px;
    gap: 5px;
    width: 90%;
    margin: auto;
    overflow-x: scroll;
    box-sizing: border-box;
}
.imagePreviewContainer2{
    display: flex;
    padding: 5px;
    gap: 5px;
    width: 90%;
    margin: auto;
}
.images-slider{
    position: fixed;  
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); 
  cursor: pointer; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-repeat: no-repeat;
}
.images-slider1{
    width: 100%;  
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
}
.image-container{
    display: flex;
  justify-content: center; /* Center images horizontally */
  align-items: center;     /* Center images vertically */
  max-width: 100%;             /* Make container take full width */
  max-height: 100%;    
  background-repeat: no-repeat;   
}
.images-slider img{
    max-width: 100vw;         /* Ensure the image fits within the container */
  max-height: 100vh;        /* Ensure the image height fits */
  object-fit: contain;
  margin: auto; 
  background-repeat: no-repeat;
}
.extra-img{
    visibility: hidden;
}
.imagePreviewContainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    display: none;
  }
  
  .imagePreviewContainer::-webkit-scrollbar-thumb {
    background-color: #ddd; /* Customize thumb color */
    border-radius: 1px; /* Optional: Rounded corners */
  }
  
  .imagePreviewContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Customize track color */
    border-radius: 1px; /* Optional: Rounded corners */
  }

.imagePreviewContainer img{
    width: 200px;
    height: 150px;
    border-radius: 8px;
}

.collywobblesleftsideandheading{
    background-color: none;
    color: var(--blackcolor);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 1rem;

}

.leftarrowicon{
    font-size: 4rem;
    cursor: pointer;
}

.collywobblestextdiv{
    margin: 0 auto;
}

.collywobblestextdiv h1{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.8rem;
}

.anlysisbtndiv{
    text-align: left;
    padding-inline: 2rem;
    padding-top: 2rem;
}

.anlysisbtndiv button{
    color: #2F7BD5;
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    background-color: #D1ECFF;
    box-shadow: 0px 0px 4px 0px #00000040;
border: none;
border-radius: 5px;
padding: 1rem 2rem;
cursor: pointer;
display: flex;
}

.personalinfodiv{
    padding-inline: 2rem;
    text-align: right;
}

.personalinfodiv h1{
    color: #5D5D5DC7;
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
    border-bottom: 1px solid #5D5D5D78;
    padding-block: 1rem;

}

.personalinfodiv p{
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
    padding-top: 1rem;
}

.personalinfodiv span{
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
}



.imagesshowdivcolly{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding-block: 2rem;
    }
    
    .imagesshowdivcolly img{
        width: 200px;
    }

    .collybtndiv{
        text-align: center;
    }

    .collybtndiv button{
        font-family: "AvenirRegular";
        font-weight: 500;
        font-size: 1.6rem;
        background-color: var(--purplecolor);
        border: none;
        padding: 1rem 2rem;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        
    }


    .collyinputdiv{
        text-align: right;
        padding-inline: 2rem;
    }

    .collyinputdiv h6{
        color: #5D5D5DC7;
        font-family: "AvenirRegular";
        font-weight: 800;
        padding: 5px;
        font-size: 1.5rem;
        border-bottom: 1px solid #5D5D5D78;
        padding-block: 0.5rem;
    
    }

    .collyinputdiv textarea{
        font-family: "AvenirRegular";
        font-weight: 400;
        font-size: 1.5rem;
        border: none;
        width: 100%;
        height: 30vh;
        text-align: right;
        padding-top: 1rem;
        font-size: 16px;
    }

    .collyinputdiv p{
        color: #5D5D5DC7;
        font-family: "AvenirRegular";
        font-weight: 800;
        font-size: 1.5rem;
    }

.placholdertextshow{
    padding-top: 20px;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 20px;
}

.nutrientdiets{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding-block: 20px;
}

.inprogressredbut{
    background-color: red!important;
}

/* this si the css of the popupbox */

.delpopupmain{
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0px;
left: 0px;
z-index: 1;
}

.delpopupcontainer{
    width: 260px;
height: 100px;
background-color: #FAFAFA;
border: 1px solid var(--purplecolor);
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
flex-direction: column;
z-index: 1;
}



.titldivup{
font-size: 14px;
font-weight: 500;
color: red;
text-align: center;
}
.buttonsdivtwo{
display: flex;
gap: 5px;
}
.buttonsdivtwo button:nth-of-type(1){
border: none;
padding: 6px 12px;
background-color: gray;
color: white;
font-size: 14px;
font-family: 500;
border-radius: 6px;
cursor: pointer;
}

.buttonsdivtwo button:nth-of-type(2){
    border: none;
padding: 6px 12px;
background-color: red;
color: white;
font-size: 14px;
font-family: 500;
border-radius: 6px;
cursor: pointer;
}

@media(max-width:430px){
    .imagesshowdivcolly img{
        width: 95px;
    }
}

