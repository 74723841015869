.videouploadingmain{
display: flex;
flex-direction: column;
gap: 30px;
justify-content: center;
align-items: center;
background-color: #FAFAFA;
}

.videouploadingnavbar{
width: 100%;
}

.videouplaodingcontainer{
width: 90%;
display: flex;
flex-direction: column;
gap: 20px;

}

.videotitlediv{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: 700;
    border-top: 1px solid var(--purplecolor);
    border-bottom: 1px solid var(--purplecolor);
    padding-block: 10px;
}

.backicondivabso{
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 22px;
    cursor: pointer;
}

.navigationback{
flex-direction: row-reverse;
font-size: 16px;
font-weight: 600;
display: flex;
    justify-content: end;
    gap: 5px;
}

.navigationback span:nth-last-of-type(1){
color: var(--purplecolor);
}

.navigationbuttonmainbook{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.navigationbuttonmainbook div{
    background-color: white;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
color: #5D5D5D;
cursor: pointer;
}

.navigationactive{
    background-color: var(--purplecolor) !important;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    box-shadow: none !important;
color: white !important;
cursor: pointer;
}

.uploadedvideosmaindiv{
width: 100%;
border-radius: 12px;
background-color: var(--purplecolor);
padding-block: 10px;
display: flex;
flex-direction: column;
gap: 5px;
justify-content: center;
align-items: center;
}

.uploadingvideobutton{
display: flex;
justify-content: center;
align-items: center;
gap: 15px;
}


.uploadingvideobutton{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 5px;
}

.buttondivbackground{
width: 30px;
height: 30px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttondivbackground svg{
    color: var(--purplecolor);
    font-size: 25px;
    font-weight: 600;
    cursor: pointer;
}

.buttondivtitle{
color: white;
font-size: 16px;
font-weight: 600;
}


.videouploadingfooter{
width: 100%;
}


