
.callustopmaindiv{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: #FAFAFA;
}

.Callus_form_maindiv{
    width: 90%;
    /* height: 100vh; */
    text-align: end;
    /* padding-inline: 3rem; */
    padding-block: 2rem;
}

.Callus_form_maindiv form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Callus_form_maindiv h1{
    color: var(--graycolor);
    font-family: "Cairo";
	font-weight: 600;
	font-size: 2.6rem;
    text-align: center;
}
.Callus_form_maindiv h2{
    color: var(--graycolor);
    font-family: "Cairo";
	font-weight: 500;
	font-size: 1.6rem;
    text-align: center;
}

.Callus_form_maindiv p{
    color: var(--graycolor);
    font-family: "Cairo";
	font-weight: 600;
	font-size: 1.6rem;
}

.Callus_form_maindiv input{
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #5D5D5D;
    outline: none;
    width: 100%;
    text-align: end;
    background-color: #fff;
font-size: 16px;
}

.Callus_form_maindiv textarea{
    width: 100%;
    height: 20vh;
    outline: none;
    text-align: end;
    padding: 1rem;
    border-radius: 6px;
    font-size: 16px;
}


#callusbtncenterdiv{
    text-align: center;
    margin-top: 2rem;
}

.Callus_form_maindiv button{
    background: var(--purplecolor);
    color: #fff;
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.5rem;
    border-radius: 3rem;
    border: none;
    cursor: pointer;

    padding: 0.5rem 2rem;
}


.callusfooter{
    width: 100%;
    margin-top: 20px;
}

