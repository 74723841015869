.addanewvideomain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: "Cairo", sans-serif;
}

.newvideonav {
    width: 100%;
}



.newvideocontainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.videodivnewvideo {
    width: 80%;
    
    height: 35vh;
    border-radius: 12px;
}

.videodivnewvideo video {
    height: 35vh;
    width: 100%;object-fit: cover;
    border-radius: 12px;
}

.videodivnewvideo label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    color: var(--blackcolor);
    cursor: pointer;
    background-color: #D9D9D9;
    font-size: 30px;
}

.videosbuttons {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
}

.videosbuttons button {
    background-color: white;
    color: var(--blackcolor);
    border: 1px solid var(--blackcolor);
    border-radius: 12px;
    padding: 8px 20px;
    font-family: "Cairo", sans-serif;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}
.videosbuttons label {
    background-color: white;
    color: var(--blackcolor);
    border: 1px solid var(--blackcolor);
    border-radius: 20px;
    padding: 6px 20px;
    font-family: "Cairo", sans-serif;
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
}

.addanewvideoandplusbut {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--blackcolor);
    padding-bottom: 5px;
}

.addanewvideoandplusbut div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.addanewvideoandplusbut div:nth-of-type(2) {
    width: 25px;
    height: 25px;
    background-color: var(--blackcolor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.addvideobuttonnew {
    background-color: var(--purplecolor);
    color: white;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


.newvideofooter {
    width: 100%;

}


/* this is the css of the show popup */

.showpopupmain {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.418);
}

.showpopupcontainer {
    background-color: white;
    border-radius: 12px;
    width: 90%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: end;
    padding: 10px;
}

.showpopupcontainer div{
    width: 100%;
    text-align: end;
}

.showpopupcontainer div:nth-last-of-type(1){
    text-align: center;
}

.showpopupcontainer label {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-align: end;
}

.showpopupcontainer input {
    text-align: end;
    width: 100%;
    border-radius: 12px;
    height: 35px;
    outline: none;
    border: 1px solid #5D5D5D;
    box-shadow: 0px 0px 10px 0px #00000040;
    padding-right: 20px;
}

.showpopupcontainer button {
    margin: auto;
    width: 100px;
    height: 40px;
    background: #29292C;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
}

.topupernavbar {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.topupernavbar div svg {
    cursor: pointer;
}

.topupernavbar div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.belowdivwithborder textarea::placeholder{
    color: var(--purplecolor);
}





/* this is the css for the qestions that is added in the form */

.showthequestionsinthediv {
    width: 100%;
    max-height: 80px;
    overflow: hidden;
    transition: max-height 0.5s ease;
    border-radius: 12px;
    display: flex;
    justify-content: end;
    position: relative;
    border: 1px solid var(--graycolor);
}

.showthequestionsinthediv.visible {
    max-height: 1000px;
    /* transition: max-height 0.5s ease; */
}

.downarrowdiv {
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
}

.display-questions {
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: end;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
}

.questioncardmaindiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.display-questions div:nth-of-type(4),
.display-questions div:nth-of-type(5),
.display-questions div:nth-of-type(6) {
    display: flex;
    gap: 10px;
}

.threedotsandquestion {
    display: flex;
    gap: 10px;
    flex-direction: row-reverse;
    font-size: 14px;
    font-weight: 600;
}

.backgrountoone {
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
}

@media(max-width:430px){
    .videosbuttons button {
        font-size: 12px;
    }
}


.upload-progress {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    border: 2px solid #00000026;
    height: 25px;
    border-radius: 12px;
    padding-inline: 3px;
  }
  
  .progress-bar {
    width: 100%;
    height: 18px;
    background-color: #B571AC;
    transition: width 0.4s ease-in-out;
    border-radius: 12px ;
  }
  
  .progress-text {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  

