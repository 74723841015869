.uploadvideomain {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-family: "Cairo", sans-serif;
    background-color: 
#FAFAFA;
}

.uploadvideonavbar {
    width: 100%;
}

.uploadvidocontainer {
    /* padding-top: 30px; */
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.video {
    width: 100%;
    height: 40vh;
    background-color: #D9D9D9;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video svg{
    font-size: 30px;
    color: black;
}
.video video{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: 8px;
}

.topupernavbar{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;font-size: 18px;
    font-weight: 600;
    position: relative;
}
.topupernavbar div:nth-of-type(1){
    position: absolute;
    top: 0px;
    left: 10px;
}

.topupernavbar div:nth-of-type(2){
color: var(--purplecolor);
font-weight: 700;
}
.uploadyoutubevid {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.uploadyoutubevid label {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: var(--graycolor);
    padding-bottom: 5px;
}

.uploadyoutubevid input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--purplecolor);
    border-radius: 10px;
    outline: none;
    text-indent: 10px;
    font-size: 16px;
    margin-top: 0.2rem;
}

.uploadyoutubevid input::placeholder {}

.uplaodavideophone {
    background-color: var(--purplecolor);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2rem;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
    direction: rtl;
}

.titleofvideo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.titleofvideo label {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: var(--graycolor);
}

.titleofvideo input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--purplecolor);
    border-radius: 10px;
    outline: none;
    text-indent: 10px;
    font-size: 15px;
    padding-right: 20px;
    font-family: "Cairo", sans-serif;
    direction: rtl;
}

.titleofvideo input::placeholder {
    color: var(--purplecolor);
}

.descraptionofvideo {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    gap: 5px;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
}

.descrationvideoof {
    color: var(--graycolor);
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}

.belowdivwithborder {
    width: 100%;
}

.belowdivwithborder textarea {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid var(--purplecolor);
    outline: none;
    font-size: 16px;
    text-align: end;
    padding-right: 15px;
    padding-top: 5px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}

.buttonbelowdiv{
    width: 100%;
    text-align: center;
}
.buttonbelowdiv button
{
    background-color: var(--purplecolor);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 2rem;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
}
.uploadvideofooter {
    width: 100%;
}