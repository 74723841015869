.videosdivmain{
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
background-color: #FAFAFA;
}
.deleteCommentButton{
    font-size: 20px;
}
.Comment-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cmnt-del-popup{
    position: absolute;
    left: 15px;
    top: 5px;
    padding: 10px;
    background-color: red;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
}

.comment-profile-pic{
    width: 35px;
    height: 35px;
}
.comment-profile-pic img{
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border: 2px solid black;
}

.videosnavbar{
width: 100%;
}

.nocommentsdivmain{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--purplecolor);
}

.videoscontainer{
width: 90%;
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
}

.linktonavigate{
width: 100%;
text-align: end;
font-weight: 600;
font-size: 16px;
color: #5D5D5D;
}

.linktonavigate span{
    color: var(--purplecolor);
}

.videoandlikeshare{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
flex-direction: column;
}

.videodivshowvideo{
    width: 100%;
    height: 300px;
    background-color:
    #BABABB;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videodivshowvideo svg{
font-size: 30px;
color: black;
}

.likesharemain{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
font-size: 18px;
position: relative;
font-weight: 600;
color: #29292C;
}

.likesharediv{
display: flex;
gap: 10px;
}
.share{
display: flex;
flex-direction: column;
align-items: center;
}
.share svg{
color:
#BABABB;
}

.like{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.like svg{
    color:
    #BABABB;
}

.activelike {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.activelike svg{
    color: red;
}

.videotitle{
font-size: 18px;
font-weight: 600;
color: var(--purplecolor);
display: flex;
}

.showvideopopup{
    justify-content: center;
    align-items: center;
    display: flex;
    top: 90%;
}

.showvideopopup div:nth-of-type(2){
    text-align: center;
}

.subractionbutton{
width: 100%;
text-align: end;
display: flex;
justify-content: center;
}

.subractionbutton button{
border: 1px solid var(--purplecolor);
color: var(--purplecolor);
padding: 8px 16px;
font-size: 16px;
font-weight: 600;
background-color: white;
border-radius: 8px;
cursor: pointer;
}
.routesnavdiv{
width: 100%;
height: 40px;
border:  1px solid var(--purplecolor);
display: flex;
justify-content: center;
align-items: center;
gap: 50px;
font-size: 16px;
font-weight: 600;
color: var(--purplecolor);
border-radius: 6px;
flex-direction: row-reverse;
}

.active{
  border-bottom: 2px solid var(--purplecolor);
  padding-bottom: 5px;
  box-sizing: border-box;
}

.showroutescontent{
width: 100%;
border: 1px solid var(--purplecolor);
border-radius: 6px;
}

.desxraptionmaindiv{
    display: flex;
padding: 10px;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: end;
}


/* this si the css of the comments in the website */

.commetnsmaindiv{
    width: 100%;
    }

    .commentcardcontainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    }

    .commentdivmain{
    display: flex;
    justify-content: end;
    align-items: start;
    gap: 10px;
    }

    .commentdivleft{
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: end;
    }

    .commentdivleft div:nth-of-type(1){
    font-size: 14px;
    font-weight: 600;
    }
    .commentdivleft div:nth-of-type(2){
        font-size: 14px;
    font-weight: 600;
    }
    .commentdivleft div:nth-of-type(3){
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 15px;
    }

    .hearticondis{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--purplecolor);
    }

    .comliked {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--purplecolor);
    }

    .comliked svg{
        color: red;
    }

    .hearticondis svg{
        color: gainsboro;
    }

    .commentdivright{
        font-size: 25px;
    }
    .commentdivright img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .bottommessagediv{
        background: #EEEEEE;
    width: 100%;
    height: 50px;
    gap: 5px;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0px 5px;
    }
    .bottommessagediv svg{
        cursor: pointer;
        font-size: 24px;
    }
    .bottommessagediv input{
        width: 94%;
        height: 35px;
        border: none;
        outline: none;
        padding-right: 10px;
        background: #EEEEEE;
        box-shadow: 0px 0px 10px 0px #00000040;
        border-radius: 12px;
    text-align: end;
    color: black;
    font-size: 16px;
    }

    .sendicondiv{
        color: var(--purplecolor);
    }

    .bottommessagediv input::placeholder{
        color: black;
        font-size: 16px;
    }


.videosfooter{
width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1540px){
    .videoandlikeshare{
        width: 800px;
    }
    .videodivshowvideo{
        height: 450px;
    }
}

