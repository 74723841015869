
/* .heightmaindivlogin{
    height: 75vh;
} */


/* error message code  */

/* Login.css */

.error-message {
    color: #FFF; 
    background-color: #E30101; 
    padding: 10px; 
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    text-align: center; 
    font-weight: 700;
    font-family: "AvenirRegular"; 
    font-size: 1.2rem; 
    /* margin-top: -1rem; */
    top: -22px;
    width: 100%;
    position: absolute;
}

.loginmaincolordiv{
    background-color: var(--backgroundcolor);
}

.loginmaindiv {

    margin: 0 auto;
    width: 90%;
    max-width: 460px;
    margin-block: 8rem;
    box-shadow: 6.32px 6.32px 11.59px 3.16px #00000040;
    border-radius: 3rem;
    position: relative;
}

/* Add these styles to your existing CSS file */
.input-error {
    border-color: red !important;
}


.alllogindatadiv{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginandsignupbutton {
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 0.6rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding: 0.5rem;
    display: flex;
    width: 225px;
}

.loginandsignupbutton button {
    background-color: #EEEEEE;
    padding: 0.6rem 1rem;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-family: "AvenirLight";
    font-weight: 400;
    color: var(--purplecolor);
}

#loginbtn {
    background: var(--lightblack);
    color: var(--whitecolor);
    font-size: 1.6rem;
    font-family: "AvenirLight";
    font-weight: 400;
    padding: 0.6rem 1.5rem;
}

.logininputs {
    width: 100%;
}

.logininputs p {
    font-size: 1.6rem;
    font-family: "AvenirRegular";
    font-weight: 400;
    color: var(--fontcolor);
    text-align: end;
    padding-block: 1rem;
}

.logininputs input {

    padding: 1.3rem;
    width: 100%;
    /* box-shadow: 0px 0px 10.54px 0px #00000040; */
    border-radius: 1rem;
    border: 1px solid #5D5D5D;
    text-align: left;
    outline: none;
    background: #F5F5F5;
    margin-bottom: 1rem;
    font-size: 16px;
    font-family: "AvenirRegular";
    font-weight: 400;
}

#showpasswordmaindiv{
    box-shadow: 0px 0px 10.54px 0px #00000040;
    text-indent: 3rem;
    font-size: 1.5rem;
    font-family: "AvenirRegular";
    font-weight: 400;
}



.inputphonedivlogin{
    border: 1px solid red;
    padding: 0.5rem;
    font-size: 13px;
    width: 100%;
    box-shadow: 0px 0px 10.54px 0px #00000040;
    border-radius: 1rem;
    border: 1px solid #5D5D5D;
    text-align: start !important;
    outline: none;
    background: #F5F5F5;
    margin-bottom: 1rem;
    font-family: "Cairo-Regular";
    font-weight: 400;   
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #F5F5F5;
    border: none;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100%;
    outline: none;
    font-family: "Cairo-Regular";
    font-weight: 400; 
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background: #F5F5F5 !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background: #F5F5F5 !important;
}

.react-tel-input .selected-flag {
    background: #F5F5F5 !important;
}
.password-container {
    position: relative;
}

/* 
.password-container input {
    width: calc(100% - 40px);
    padding-right: 40px;
} */

.toggle-password {
    position: absolute;
    top: 43%;
    left: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #5D5D5D;
}

.loginbutton button {
    width: 100%;
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 400;
    color: #fff;
    /* padding: 0.9rem; */ 
    height: 55px;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
    font-size: 17px;
    margin-top: 1rem;
}

.loginbottomtext{
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}

.loginbottomtext span{
    color: #34B7F1;
}

.loginp p {
    text-align: center;
    color: #34B7F1;
    font-family: "AvenirLight";
    font-weight: 400;
    cursor: pointer;
    font-size: 15px;
}


/* Hide the arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-size: 16px;
}

/* Hide the arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}




/* media queries */

@media screen and (max-width:500px) {

    
    .loginmaindiv {
        width: 90%;
        /* margin-block: 5rem; */
    }

    .loginandsignupbutton {
        width: 75%;
    }
    
    .loginandsignupbutton button{
        font-size: 18px;
    }
    #loginbtn{
        font-size: 18px;
    }


    .error-message {
        font-size: 14px; 
    }

    
/* .heightmaindivlogin{
    height: 60vh;
} */
.loginfooter{
    /* position: absolute;
    bottom: 0px; */
    width: 100%;
}

}



/* change of flag font */

.react-tel-input .country-list .country-name {
    margin-right: 6px;
    font-family: "Cairo" !important;
    font-weight: 600;
    color: var(--graycolor);
    font-size: 1.5rem;
}


.react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    /* padding: 6px; */
    padding-left: 0.4rem;
    margin-top: 6px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: white;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0 0 3px 3px;
}

.react-tel-input .country-list .search-emoji {
    position: absolute;
    font-size: 14px;
    right: 1px;
    top: 18px;
}



