.invoice-data-input{
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
}
.invoice-data-input-field input{
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 300px;
    text-align: right;
}
.invoice-data-input-btn button{
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
}
.data-container-payment{
    margin: auto;
    width: 90%;
    padding: 20px;
}
.payment-date{
    font-size: 15px;
}
.payment-data-section{
    font-size: 15px;
}
.payment-details-data-container{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.377);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 15px;
}
tr{
    width: 100%;
}
td{
    width: 20%;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
h1{
    font-weight: 400;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}