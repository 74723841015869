.del-text{
    color: black;
    font-size: 20px;
    padding: 20px 10px;
}
.login-res-cont{
    background-color: rgba(204, 203, 203, 0.295);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
}
.login-res-line{
    width: 100%;
    border-bottom: 1px solid rgb(185, 185, 185);
    margin-top: 10px;
    margin-bottom: 10px;
}
.l-r-l-btn{
    padding: 8px 24px;
    background-color: #B571AC;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    margin-bottom: 5px;
    color: white;
}