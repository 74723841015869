.allbanner-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.a-b-c-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 15px;
}
.a-b-c-b-heading{
    width: 100%;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
    color: #B571AC;
}
.a-b-c-b-banners-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.a-b-c-b-b-c-img{
    width: 100%;
}
.a-b-c-b-b-c-img img{
    width: 100%;
}
.a-b-c-add-banner-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.a-b-c-add-banner-btn button{
    padding: 8px 16px;
    font-size: 14px;
    background-color: #B571AC;
    border: none;
    color: white;
    border-radius: 8px;
    cursor: pointer;
}