.addingadmin-body{
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 15px;
    gap: 15px;
}
.addingadmin-body-heading{
    font-size: 18px;
    color: #B571AC;
    font-weight: 600;
}
.addingadmin-body-number{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: end;
    width: 100%;
    font-size: 13px;
}
.addingadmin-body-number1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: end;
    width: 100%;
    font-size: 13px;
}
.addingadmin-body-number1 input{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
    direction: rtl;
    outline: none;
}
.addingadmin-body-number input{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #B571AC;
}
.addingadmin-body-button{
    display: flex;
    justify-content: center;
    width: 100%;
}
.addingadmin-body-button button{
    padding: 8px 16px;
    background-color: #B571AC;
    border: none;
    color: white;
    border-radius: 5px;
}
.addingadmin-body-number label{
    font-size: 15px;
    font-weight: 500;
}
.addingadmin-body-number1 label{
    font-size: 15px;
    font-weight: 500;
}
.m-s-bdy{
    width: 100%;
}