.create-webinar-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.webinar-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
    font-size: 14px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-group input{
    width: 100%;
    border: 1px solid #B571AC;
    border-radius: 5px;
    padding: 6px;
    font-size: 13px;
    background-color: white;
    direction: rtl;
}
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #B571AC;
    border-radius: 4px;
    font-size: 16px;
    text-align: right;
}

.submit-btn {
    background-color: #B571AC;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.webinar-form-check{
    display: flex;
    gap: 5px;
}

.webinar-list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
}

.webinar-item {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
    font-size: 14px;
}
.webinar-end-btn{
    display: flex;
    width: 100%;
    justify-content: center;
}
.webinar-item h3 {
    margin-top: 0;
}

.webinar-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-btn {
    background-color: #B571AC;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.edit-btn:hover {
    background-color: #a867a0;
}

.status-toggle {
    display: flex;
    gap: 10px;
}

.status-toggle label {
    font-weight: bold;
    cursor: pointer;
}
