.netowkerrormain{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 30px;
background-color: 
#FAFAFA;
}

.natworkerrornav{
width: 100%;
}

.networkerrorcontainer{
width: 90%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
font-size: 18px;
font-weight: 500;
height: 65vh;
}

.networkerrorfooter{
width: 100%;
}

