
.payemntdetailsmaintop{
    padding: 20px;
}

.amount-heading{
    width: 100%;
    display: flex;
    justify-content: center;
}
.amount-heading h1{
    font-weight: 900;
}
.p-d-dashed-line{
    width: 100%;
    border-bottom: 1px dashed rgb(199, 198, 198);
}
.p-d-dashed-line1{
    border-bottom: 1px dashed rgb(199, 198, 198);
    margin-inline: 15%;
}
.p-d-row-wide{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
}
.p-d-solid-line{
    width: 100%;
    border-bottom: 1px solid rgb(204, 204, 204);
}
.p-d-r-w-pinkie{
    color: #707070;
}
.detailcontentmaindiv{
    text-align: right;
    padding-inline: 2rem;
}

.detailcontentmaindiv h2{
    color: #5D5D5DC7;
    /* font-family: "AvenirRegular"; */
    font-weight: 800;
    font-size: 1.5rem;
    padding-block: 1rem;
    border-bottom: 1px solid #5D5D5D78;
}

.pandspandivpaymentdetail p{
    /* font-family: "AvenirRegular"; */
    font-weight: 500;
    font-size: 1.6rem;
    padding-block: 1rem;

}
.paymenttextdiv{
    display: flex;
    justify-content: center;
    text-align: right;
    width: 75%;
}
.pandspandivpaymentdetail h3{
    color: #5D5D5DC7;
    /* font-family: "AvenirRegular"; */
    font-weight: 800;
    font-size: 1.5rem;
    padding-block: 1rem;
    border-bottom: 1px solid #5D5D5D78;
}

.pandspandivpaymentdetail h4{
    color: #5D5D5DC7;
    /* font-family: "AvenirRegular"; */
    font-weight: 800;
    font-size: 1.5rem;
    padding-block: 1rem;
    border-bottom: 1px solid #5D5D5D78;
}


.showpaymentdiv{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-block: 1rem;
    border-bottom: 1px solid #5D5D5D78;
    font-family: "AvenirRegular";
    font-weight: 600;
    font-size: 1.5rem;
}


.btnpaymentdetaildiv{
    text-align: center;
    padding-top: 2rem;
}


.detailcontentmaindiv h6{
    text-align: center;
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.8rem;
    padding-block: 1rem;
}

.btnpaymentdetaildiv .download-btn {
    background-color: var(--purplecolor);
    color: #fff;
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    border: none;
    border-radius: 10px;
    padding: 1rem 2rem;
    width: 100%;
    cursor: pointer;
    text-align: center;
}
