.ownermaindiv{
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
font-family: "AvenirRegular";
background-color: #FAFAFA;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.ownernavbar{
width: 100%;
}

.ownerconatainermain{
width: 90%;
display: flex;
flex-direction: column;
gap: 20px;
margin-right: 20px;
}

.ownerfooter{
width: 100%;
}

.headingdiv{
    font-size: 18px;
    font-weight: 700;
    color: var(--purplecolor);
    text-align: end;
}

.paragraphdiv{
text-align: end;
font-size: 16px;
font-weight: 600;
color: #5D5D5D;
}

.lisitemsdivs{
margin-right: 20px;
}

.lisitemsdivs ul{
    direction: rtl;
font-size: 16px;
font-weight: 600;
color: #5D5D5D;
display: flex;
flex-direction: column;
gap: 10px;
}

/* this is the css for the using policy of the website */

.ownerconatainermain ol{
    direction: rtl;
    display: flex;
    flex-direction: column;
gap: 20px;
}

.headingdivpol{
    font-size: 18px;
    font-weight: 700;
    color: var(--purplecolor);
}

.paragraphdivpol{
font-size: 16px;
font-weight: 600;
color: #5D5D5D;
}
.paragraphdivpol ul{
    direction: rtl;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: disc;
}