.suceessmessmain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    font-family: "Cairo", sans-serif; 
    
    }
    
    .sucessnavbar{
    width: 100%;
    }
    
    .sucesscontainerfsdf{
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    }
    
    .circlegreentick{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #48B865;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .circlegreentick svg{
        font-size: 80px;
        font-weight: 600;
    }
    .suceesmessageis{
    font-size: 20px;
    font-weight: 600;
    color: #48B865;
    }
    
    .sucessmessagefooter{
    width: 100%;
    }
    
    /* this is the css of the error message */
    
    .circleredick{
        width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .circleredick svg{
        font-size: 80px;
        font-weight: 600;
    }
    .errormessageis{
    font-size: 20px;
    font-weight: 600;
    color: red;
    }
    
    @media (max-width:430px){
        .sucessmessagefooter{
            width: 100%;
            position: absolute;
            bottom: 0px;
            }
            .sucesscontainerfsdf{
                height: 80vh;
                /* height: auto; */
            }
    }