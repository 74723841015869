
.booktopmaidiv{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 90%;
}
.booktopmaidiv1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    width: 90%;
}
.minloadingdiv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.29); */
    background-color: #0000003b;
    z-index: 3;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .book-img{
    width: 100%;
    height: 260px;
    object-fit: cover;
  }

  .minloadingdiv svg{
    font-size: 50px;
    color: var(--purplecolor);
  }
.mainbookdiv{
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 350px;
    background-color: white;
    border: 1px solid var(--purplecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 18px;
    overflow: hidden;
    flex-direction: column;
}
.mainbookdiv1{
    text-align: center;
    margin: auto;
    width: 350px;
    background-color: white;
    border: 1px solid var(--purplecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 18px;
    overflow: hidden;
    flex-direction: column;
}

.mainbookdiv img{
    width: 100%;
}
.mainbookdiv1 img{
    width: 100%;
}
.LoadingInFetchingBooksContainer{
    position: relative;
    width: 100%;
    height: 400px;
}
.LoadingInFetchingBooks{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoadingInFetchingBooks svg{
    font-size: 50px;
    color: var(--purplecolor);
  }
.paddingleftbotm{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.mainbookdiv h2{
    font-family: "AvenirHeavy";
    font-weight: 800;
    color: var(--purplecolor);
    font-size: 1.8rem;
    display: flex;
    position: relative;
    justify-content: center;
}
.mainbookdiv1 h2{
    font-family: "AvenirHeavy";
    font-weight: 800;
    color: var(--purplecolor);
    font-size: 1.8rem;
    display: flex;
    position: relative;
    justify-content: center;
}
.threedotsbookicon{
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.bookpopupmaindiv{
    top: 8%;
    text-align: center;
    right: 17px;
}

.mainbookdiv p{
    font-family: "Cairo";
    font-weight: 500;
    font-size: 1.6rem;
    text-align: right;
}
.mainbookdiv1 p{
    font-family: "Cairo";
    font-weight: 500;
    font-size: 1.6rem;
    text-align: right;
}

.mainbookdiv h5{
    /* font-family: "AvenirHeavy"; */
    font-weight: 800;
    color: var(--purplecolor);
    font-size: 1.8rem;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}
.mainbookdiv1 h5{
    /* font-family: "AvenirHeavy"; */
    font-weight: 800;
    color: var(--purplecolor);
    font-size: 1.8rem;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}

.nobooksmaindiv{
    width: 100%;
    height: 65vh;
    background-color: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
}
.books-container-dashboard{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
@media only screen and (min-width: 900px) and (max-width: 1540px) {
    /* Your styles for laptops */
    .books-container-dashboard{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .mainbookdiv{
        margin: 0;
        max-width: 270px;
    }
  }


@media(max-width:430px){
    .mainbookdiv img{
       width: 100%;
    }
}
