.m-a-d-about-manager{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 10px;
}
.m-a-d-a-m-upper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    font-size: 15px;
}
.m-a-d-a-m-u-pic img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.m-a-d-a-m-u-name{
    font-weight: 700;
}
.m-a-d-a-m-u-title{
    font-weight: 700;
}
.m-a-d-a-m-u-date{
    font-weight: 500;
}
.m-a-d-a-m-mid-btns{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
.m-a-d-a-m-mb-btn button{
    padding: 7px;
    width: 80px;
    font-size: 15px;
    background-color: white;
    color: #B571AC;
    border: 1px solid #B571AC;
    border-radius: 5px;
    cursor: pointer;
}
.m-a-d-a-m-down{
    width: 100%;
    direction: rtl;
    font-size: 15px;
}
.m-a-d-a-m-d-heading{
    width: 100%;
    text-align: right;
    padding: 8px;
    border-bottom: 1px solid rgb(194, 194, 194);
    font-weight: 800;
    color: #B571AC;
}
.m-a-d-a-m-ul{
    padding: 20px;
    list-style-type: none;
}
.m-a-d-a-m-ul li::before{
    color: #B571AC;
    content: '\2022';
    font-size: 2em;
    margin-left: 10px;
}