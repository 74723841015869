.newtopsimplenavbar{
    width: 100%;

}

.simplenavbarmaindiv{
    /* background-color: var(--whitecolor); */
     background-color: #F9EAF7;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100px;
    width: 100%;
}

.simplenavbarmaindiv img{
    width: 55px;
    height: 52px;
}

.simplenavbarmaindiv h2{
    color: var(--purplecolor);
    font-family: "OldAnticDecorativeRegular";
    font-weight: 400;
    font-size: 4rem;
}

.maintopnavbardiv{
    width: 100%;
}

@media screen and (max-width:500px) {

    
    .simplenavbarmaindiv{
        height: 100px;
        font-size: 16px;
    }
    
    .simplenavbarmaindiv img{
        /* width: 170px; */
        height: 45px;
    }
}


