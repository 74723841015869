.latestmods-body{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: whitesmoke;
}
.latestmods-head{
    display: flex;
    justify-content: end;
    font-size: 18px;
    color: #B571AC;
    font-weight: 700;
}
.latestmods-buttons{
    display: flex;
    gap: 10px;
    justify-content: end;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    padding: 15px;
    padding-inline: 5px;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
    scrollbar-width: none;
}
.latestmods-buttons::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}

.lmb-active button{
    box-shadow: 0px 0px 10px 1px #00000040;
    padding: 10px;
    cursor: pointer;
    border-radius: 23px;
    border: none;
    background-color: #B571AC;
    color: white;
    font-size: 15px;
    width: 150px;
}
.lmb button{
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 10px;
    cursor: pointer;
    border-radius: 23px;
    border: none;
    background-color: white;
    color: #B571AC;
    font-size: 15px;
    width: 150px;
}

/* Video Editing Styling */

.videoedited-profile-box{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 5px;
    background-color: #B571AC;
    color: white;
    border-radius: 5px;
    gap: 10px;
}
.videoedited-profile-profile-img{
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid white;
    border-radius: 50%;
}
.videoedited-profile-profile-img img{
    width: 38px;
}
.videoedited-profile-box-profile{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
}
.videoedited-profile-box-line{
    height: 60px;
    border: 1px solid white;
}
.videoedited-profile-box-editings{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
    font-size: 12px;
}
.videoedited-profile-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.videoedited-profile-wholedata{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Video Deleted Styling */

.videodeleted-profile-box{
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 5px;
    background-color: #E51717;
    color: white;
    border-radius: 5px;
    gap: 10px;
}

/* Comment deleted styling */

.commentdeleted-downbox{
    display: flex;
    flex-direction: column;
    align-items: end;
    background-color: white;
    border: 1px solid #B571AC;
    border-radius: 12px;
    justify-content: center;
    padding: 15px;
    gap: 10px;
}
.commentdelted-downbox-profile{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;
}
.commentdeleted-downbox-profile-img{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #B571AC;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    overflow: hidden;
}
.commentdeleted-downbox-profile-img img{
    width: 30px;
}
.commentdeleted-downbox-comment{
    padding-right: 45px;
    font-size: 14px;
}
.commentdeleted-downbox-likes{
    margin-right: 45px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: whitesmoke;
    color: #B571AC;
    font-size: 14px;
    padding: 5px;
    border-radius: 23px;
}