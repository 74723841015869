.ui-interviews{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
    padding: 10px;
    border: 1px solid #B571AC;
    background-color: white;
    border-radius: 10px;
}
.ui-i-info{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 10px;
    font-size: 17px;
}
.ui-i-i-date{
    color: #B571AC;
}
.ui-i-i-title{
    color: black;
    font-weight: 700;
}
.ui-i-i-bold{
    font-weight: 700;
}
.ui-i-description{
    border-bottom: 1px solid #5D5D5D30;
    border-top: 1px solid #5D5D5D30;
    padding: 10px;
    font-size: 15px;
    color: #5D5D5D;
    text-align: right;
}
.ui-interviews-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}