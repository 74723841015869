.thank-you-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    font-family: 'Helvetica Neue', sans-serif;
    text-align: center;
    padding: 20px;
}

.thank-you-message {
    background: #fff;
    padding: 40px 60px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
}

.thank-you-message h1 {
    font-size: 2.5em;
    color: #4CAF50;
    margin-bottom: 20px;
    animation: fadeIn 1.5s ease-in-out;
}

.thank-you-message p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
}

.instructions {
    font-size: 1em;
    color: #666;
    margin-bottom: 30px;
    animation: fadeIn 2.5s ease-in-out;
}

.back-home-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    animation: fadeIn 3s ease-in-out;
}

.back-home-btn:hover {
    background-color: #45a049;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
