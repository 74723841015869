
.paymentsuccess-container{
    margin: auto;
    padding-top: 90px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.paymentsuccess-successmessage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.success-icon{
    font-size: 35px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #48B865;
}
.fail-icon{
    font-size: 35px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
}
.payment-details{
    width: 100%;
    font-size: 20px;
}
.payment-data{
    font-size: 20px;
}
.success-message{
    color: #48B865;
    font-size: 20px;
    padding: 10px;
}
.fail-message{
    color: red;
    font-size: 20px;
    padding: 10px;
}
.success-message2{
    color: #48B865;
    font-size: 18px;
    padding: 10px;
    text-align: center;
}.fail-message2{
    color: gray;
    font-size: 18px;
    padding: 10px;
    text-align: center;
}
.sidedotted-line{
    border-top: 1px dotted #B571AC;
    width: 100px;
}
.payment-data{
    width: 100%;
}
.showmore-success{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: #B571AC;
    font-size: 15px;
    gap: 10px;
}
.success-bill-details{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dotted-line{
    width: 100%;
    border-bottom: 1px dotted #C0C0C0;
}
.line-data{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.data-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
.bold-line{
    width: 100%;
    border-bottom: 1px solid #C0C0C0;
}
.reciept-download{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    color: #B571AC;
    cursor: pointer;
    text-decoration: none;
}
.link{
    text-decoration: none;
}
.end-button{
    width: 100%;
    padding: 10px;
}
.end-button button{
    background-color: #B571AC;
    width: 100%;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 7px;
}
.first-element{
    color: #B571AC;
}