.topnavbardivmain{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}

.topnavbardivmain svg{
    font-size: 22px;
    cursor: pointer;
}