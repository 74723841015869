.textchecknotimain{
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
flex-direction: column;
background-color: #FAFAFA;
}

.textchecknotifinav{
width: 100%;
}

.texchecknotificontainer{
width: 90%;
display: flex;
flex-direction: column;
gap: 15px;
justify-content: center;
align-items: center;
}

.activebuttondiv{
    /* font-family: "AvenirRegular"; */
    font-size: 16px;
    background-color: var(--purplecolor);
    border: none;
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;
    color: #fff;
font-weight: 600;
}

.norequestmaindiv{
    width: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    background-color: white;
    border-radius: 12px;
}


.unactiveclass{
    /* font-family: "AvenirRegular"; */
    font-size: 16px;
    background-color: white !important;
    border: 1px solid var(--purplecolor) !important;
    border: none;
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;
    color: black;
font-weight: 600;
}

.navigationroutesmain{
    width: 100%;
    padding-top: 10px;
display: flex;
justify-content: end;
align-items: center;
gap: 15px;
flex-direction: row-reverse;
font-size: 16px;
font-weight: 600;
}

.navigationroutesmain div:nth-of-type(1){
background-color: #FFFFFF;
border: 1px solid #B571AC;
padding: 10px 16px;
border-radius: 20px;
cursor: pointer;
color: #B571AC;
}

.navigationroutesmain div:nth-of-type(2){
    background-color: #FFFFFF;
    border: 1px solid #B571AC;
    padding: 10px 16px;
    border-radius: 20px;
    cursor: pointer;
    color: #B571AC;
}

.navigationroutesmain div:nth-of-type(3){
    background-color: #FFFFFF;
    border: 1px solid #B571AC;
    padding: 10px 16px;
    border-radius: 20px;
    cursor: pointer;
    color: #B571AC;
}

.cardsdivmain{
display: flex;
flex-direction: column;
gap: 15px;
width: 100%;
/* justify-content: center; */
align-items: center;
}

.notificationcardsmain{
display: flex;
gap: 20px;
border: 1px solid #B571AC;
width: 100%;
border-radius: 12px;
}

.notificationcardleft{
width: 30%;
display: flex;
justify-content: center;
align-items: center;
font-size: 16px;
font-weight: 600;
background: var(--purplecolor);
border-radius: 12px 0px 0px 12px;
color: white;
position: relative;
}

.closebuttondiv{
position: absolute;
bottom: 3px;
left: 35%;
}
.closebuttondiv button{
    font-family: "AvenirRegular";
    font-size: 12px;
    background-color: var(--purplecolor);
    border: none;
    padding: 6px 14px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
}

.notificatoncardright{
display: flex;
width: 70%;
flex-direction: column;
gap: 5px;
justify-content: end;
padding: 5px;
font-size: 14px;
font-weight: 600;
}

.givepurplecolor{
    color: var(--purplecolor);
}

.noticardtopdiv{
display: flex;
justify-content: space-between;
}

.notiardtopleft{

}

.noticardtopright{
display: flex;
gap: 5px;
flex-direction: column;
}

.noticardtopright div{
    text-align: end;
}

.noticardbottomdiv{
text-align: end;
padding-top: 5px;
border-top: 1px solid #5D5D5D30;
font-weight: 400;
color: #5D5D5D;
}


.textchecknotififooter{
    width: 100%;
}



/* this is the css of the tahalalet section */

.navigationbuttons{
    display: flex;
    gap: 10px;
}



@media(max-width:768px){

    .notificatoncardright{
        font-size: 10px;
    }

    .notificationcardleft{
        font-size: 12px;
    }

    .closebuttondiv {
        position: absolute;
        bottom: 3px;
        left: 0%;width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
 
    .closebuttondiv button{
        font-size: 10px;
    }
    
}


.conformationboxmain{
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
z-index: 1;
/* background-color: red; */
}

.showconfirmcontainer{
width: 200px;
height: 200px;
background-color: white;
border-radius: 12px;
border: 1px solid var(--purplecolor);
display: flex;
justify-content: center;
flex-direction: row-reverse;
align-items: center;
gap: 5px;
}

.showconfirmcontainer button{
    font-family: "AvenirRegular";
    font-size: 16px;
    /* background-color: var(--purplecolor); */
    background-color: gray;
    border: none;
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
}

.showconfirmcontainer button:nth-of-type(1){
    font-family: "AvenirRegular";
    font-size: 16px;
    background-color: var(--purplecolor);
    border: none;
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
}
