.manager-specialization-body{
    /* display: ; */
    width: 100%;
    padding: 20px;
}
.m-s-headway{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 20px;
}
.m-s-h-way{
    display: flex;
    gap: 5px;
}
.m-s-bdy{
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 10px;
}
.m-s-body{
    width: 100%;
}
.m-s-b-head{
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    text-align: right;
    border-bottom: 1px solid #5D5D5D78;
    padding: 5px;
    color: #B571AC;
}
.m-s-b-body{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
    padding: 5px;
}
.m-s-b-b-row{
    display: flex;
    gap: 5px;
    font-size: 13px;
    color: #5D5D5D;
}
.m-s-endbtn{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.m-s-endbtn button{
    padding: 5px 20px;
    font-weight: 500;
    background-color: #B571AC;
    color: white;
    border: none;
    border-radius: 5px;
}
.m-s-h-divs{
    font-weight: 500;
    color: #5D5D5D;
}
.m-s-h-divs1{
    font-weight: 500;
    color: #B571AC;
}
.m-s-b-body{
    padding-top: 15px;
}