.uploadmaindiv{
display: flex;
flex-direction: column;
gap: 30px;
justify-content: center;
align-items: center;
background-color: 
#FAFAFA;
}

.uplaodbooknavbar{
width: 100%;
}

.uploadbookcontainer{
width: 90%;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
flex-direction: column;
}

.bookcontainernew{
/* width: 100%; */
/* height: 40vh; */
/* background-color: #D9D9D9; */
border-radius: 12px;
}
.bookcontainernew img{
    width: 100%;
    height: 40vh;
    object-fit: contain;
}
.bookcontainerdiv img{
width: 100%;
height: 40vh;
object-fit: contain;
}

.uploadbookcontainer button{
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 16px;
    border-radius: 1rem;
    border: none;
    white-space: nowrap;
    background: var(--purplecolor);
    outline: none;
    cursor: pointer;
    color: white;

    padding: 12px 16px;
}

.inputmaindivtitle{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: end;
    width: 100%;
}


.inputmaindivtitle label ,.leftshortdiv label,.rightlonginput label{
text-align: end;
font-size: 16px;
font-weight: 600;
    font-family: "AvenirRegular";
    color: #5D5D5D;
}
.inputmaindivtitle input,.leftshortdiv input,.rightlonginput input{
    width: 100%;
    height: 40px;
    border: 1px solid var(--purplecolor);
    border-radius: 10px;
    outline: none;
    text-indent: 10px;
    font-size: 14px;
    text-align: end;
    padding-right: 20px;
    font-family: "Cairo", sans-serif;
}

.inputmaindivtitle input::placeholder,.leftshortdiv input::placeholder,.rightlonginput input::placeholder{
    color: var(--purplecolor);
}


.twoinputsmaindiv{
display: flex;
align-items: center;
width: 100%;
gap: 10px;
}


.leftshortdiv{
width: 30%;
display: flex;
flex-direction: column;
gap: 5px;
}

.rightlonginput{
width: 70%;
display: flex;
flex-direction: column;
gap: 5px;
}

.descraptiondivmain{
    display: flex;
    gap: 5px;
    width: 100%;
    flex-direction: column;
}

.descraptiondivmain label{
    text-align: end;
    font-size: 16px;
    font-weight: 600;
        font-family: "AvenirRegular";
        color: #5D5D5D;
}

.descraptiondivmain textarea{
    width: 100%;
    border: 1px solid var(--purplecolor);
    border-radius: 10px;
    height: 20vh;
    outline: none;
    text-indent: 10px;
    font-size: 14px;
    text-align: end;
    padding-right: 20px;
    padding-top: 5px;
    font-family: "Cairo", sans-serif;
}

.booknamedivbookicon{
font-weight: 500;
width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booknamedivbookicon svg{
    color: var(--purplecolor);
    font-size: 18px;
}
.uplaodbookfooter{
    width: 100%;
}

