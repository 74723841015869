/* Allmanagers Styling */
.allmanager-container-box{
    padding: 12px;
    border-radius: 14px;
    border: 1px solid #B571AC;
    width: 152px;
    height: 126px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
    font-size: 15px;
}
.allmanager-3dots{
    position: absolute;
    top: 1px;
    right: 1px;
    color: #5D5D5D;
    font-size: 30px;
}
.allmanager-profileicon img{
    width: 60px;
}
.allmanager-container{
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
    justify-content: center;
}
.allmanager-main{
    margin: auto;
    background-color: whitesmoke;
}
.allmanager-head{
    display: flex;
    justify-content: end;
    font-size: 18px;
    color: #B571AC;
    font-weight: 700;
}
.allmanager-main{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.allmanager-popup{
    position: absolute;
    top: 30px;
    right: 20px;
    background-color: white;
    border-radius: 12px;
    color: #5D5D5D;
    display: flex;
    flex-direction: column;
    font-size: 15px;
}
.allmanager-container-box{
    position: relative;
    background-color: white;
}
.allmanager-popup-line{
    width: 100%;
    border: 1px solid #5D5D5D;
}
.allmanager-popup-text{
    padding: 10px;
    cursor: pointer;
}

/* ManagerActivity Styling */

.manageractivity-main{
    display: flex;
    flex-direction: column;
    font-weight: 700;
    padding: 10px;
    background-color: whitesmoke;
}
.manageractivity-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.manageractivity-back{
    font-size: 30px;
    cursor: pointer;
}
.manageractivity-nav-text{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #5D5D5D;
    font-size: 20px;
}
.managementactivity-nav-name{
    color: #B571AC;
}
.manageractivity-nav-text div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.manageractivity-body-managerprofile{
    display: flex;
    justify-content: center;
}
.manageractivity-body-profile{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 179px;
    height: 139px;
    align-items: center;
    position: relative;
    border: 1px solid #B571AC;
    border-radius: 14px;
    font-size: 18px;
    background-color: white;
}
.manageractivity-body-profile-dots{
    position: absolute;
    top: 1px;
    font-size: 30px;
    right: 1px;
}
.manageractivity-body-profile-date{
    font-size: 12px;
}
.manageractivity-body-profile-pic img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #B571AC;
}
.manageractivity-body-profile-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 16px;
}
.manageractivity-body-activity{
    display: flex;
    justify-content: end;
    font-size: 20px;
    color: #B571AC;
}
.manageractivity-body{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}
.manageractivity-body-activities-box{
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid #B571AC;
    overflow: hidden;
    width: 160px;
    height: 120px;
    align-items: center;
}
.manageractivity-body-activities-dateandtime{
    padding: 10px;
    font-size: 14px;
    background-color: #B571AC;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
}
.manageractivity-body-activities-things{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    height: 100%;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    background-color: white;
}
.manageractivity-body-activities-container{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

/* ManagerDetails Styling */ 

.managerdetail-main{
    display: flex;
    flex-direction: column;
    font-weight: 700;
    padding: 10px;
    background-color: whitesmoke;
    gap: 10px;
}
.managerdetails-body-profile{
    display: flex;
    justify-content: center;
}
.managerdetails-body-profile-box{
    display: flex;
    justify-content: center;
    position: relative;
    gap: 8px;
    padding: 8px;
    align-items: center;
    border: 1px solid #B571AC;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 400;
    background-color: white;
}
.managerdetails-body-profile-box-3dots{
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 30px;
}
.managerdetails-body-profile-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 10px;
    font-size: 13px;
}
.managerdetails-body-profile-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100px;
}
.managerdetails-body-profile-right img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #B571AC;
}
.managerdetails-body-buttons{
    display: flex;
    justify-content: end;
}
.managerdetails-body{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.managerdetails-body-buttons-container{
    display: flex;
    gap: 10px;
}
.managerdetails-body-buttons-container1{
    display: flex;
    flex-direction: row-reverse;
    overflow: auto;
    gap: 10px;
}
.managerdetails-body-button-mod button{
    background-color: white;
    padding: 8px 10px;
    border: none;
    border-radius: 23px;
    cursor: pointer;
    font-size: 13px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.103);
    color: #B571AC;
}
.managerdetails-body-button-mod-active button{
    background-color: #B571AC;
    padding: 8px 10px;
    border: none;
    border-radius: 23px;
    cursor: pointer;
    font-size: 13px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.103);
    color: white;
}
.managerdetails-body-profile-name-date{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.managerdetails-video-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;
    gap: 5px;
    border: 1px solid #B571AC;
    overflow: hidden;
    border-radius: 12px;
    background-color: white;
}
.managerdetails-video-box-right{
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 8px;
    gap: 8px;
    width: 45%;
    height: 100%;
    overflow: auto;
}
.managerdetails-right-titletime{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 3px;
}
.managerdetails-video-box-left{
    width: 50%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}
.managerdetails-video-box-left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.managerdetails-video-box-left video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.managerdetails-right-description{
    overflow: auto;
}
.managerdetails-right-heading{
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}
.managerdetails-right-time{
    font-weight: 400;
    color: #b458a8;
}
.managerdetails-right-description{
    color: #5D5D5D;
    text-align: end;
    font-weight: 300;
    font-size: 12px;
    height: 55px;
}
.managerdetails-video-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.managerdetails-modifications-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.managerdetails-modifications-box-upper{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 8px;
    align-items: end;
    background-color: #B571AC;
    border-radius: 5px;
    color: white;
    font-weight: 400;
}
.managerdetails-modifications-upper-tareekh{
    font-size: 15px;
}
.managerdetails-modifications-upper-changes{
    font-size: 14px;
}
.managerdetails-modification-box{
    display: flex;
    flex-direction: column;
    gap: 3px;
}