
.bloodanalysismain{
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
}

.bloodanalysisnav{
width: 100%;
}

.bloodhaead{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}

.bloodanalysiscontaier{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: end;
    }

.attrachfiles{
font-size: 18px;
font-weight: 600;
color: #5D5D5DC7;
border-bottom: 0.37px solid #5D5D5D78;
padding-bottom: 5px;
}

.imagesshowdiv{
display: flex;
flex-direction: row;
gap: 10px;
justify-content: end;
}

.imagesshowdiv img{
    width: 300px;
}

.descraptiondivtit{
    font-size: 18px;
    font-weight: 600;
    color: #5D5D5DC7;
border-bottom: 0.37px solid #5D5D5D78;
padding-bottom: 5px;
}

.descraptiondiv{
padding-block: 10px;
font-size: 14px;
font-weight: 600;
}

.doublelinewait{
    border-bottom: 0.37px solid #5D5D5D78;
    text-align: center;
    border-top: 0.37px solid #5D5D5D78;
    padding-block: 10px;
    font-weight: 600;
    font-size: 16px;
}

/* after request except css */

.buttondivrequestpage{
width: 100%;
text-align: center;
padding-top: 20px;
display: flex;
gap: 10px;
justify-content: center;
}

.buttondivrequestpage button{
    border: none;
    background-color: var(--purplecolor);
    border-radius: 6px;
    padding: 8px 14px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-family: "Avenir Arabic";
    justify-content: center;
}


.chatboxmaindiv{
width: 100%;
margin: auto;
border-radius: 6px;
margin-top: 20px;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
}


.messagecontainer{
overflow-y: scroll;
width:90%;
padding: 10px;
height:345px;
border: 1px solid var(--purplecolor);
border-radius: 6px;
display: flex;
flex-direction: column;gap: 10px;
overflow-y: scroll;
}

.messagecontainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  .messagecontainer::-webkit-scrollbar-thumb {
    background-color: var(--purplecolor); /* Customize thumb color */
    border-radius: 1px; /* Optional: Rounded corners */
  }
  
  .messagecontainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Customize track color */
    border-radius: 2px; /* Optional: Rounded corners */
  }

.userchatmain{
display: flex;
gap: 10px;
flex-direction: row-reverse;
}
.usernamediv{
font-size: 16px;
font-weight: 700;
color: #5D5D5D;
}
.usermessagediv{
    font-size: 14px;
    font-weight: 700;
    color: #29292C;
}

.bottominputandbut{
padding: 0px 5px 0px 5px;
display: flex;
flex-direction: column;
gap: 10px;
justify-content: center;
align-items: center;
width: 100%;
background-color: white;
}

.bottominputandbut button{
    border: none;
    background-color: var(--purplecolor);
    border-radius: 6px;
    padding: 8px 14px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    font-family: "Avenir Arabic";
}
.message-underline{
    width: 100%;
    border-bottom: 1px solid #bbb8b8;
}

.date-time-chat{
    display: flex;
    gap: 10px;
    justify-content: end;
    color: #B571AC;
}

.bottominputandbut input{
width: 91%;
font-size: 16px;
height: 35px;
background-color: 
#F5F5F5;
outline: none;
border: 0.5px solid #B571AC;
border-radius: 6px;
font-size: 16px;
text-align: end;
padding-right: 10px;
}

.bottominputandbut input::placeholder{
    color: #0000004A;
font-size: 16px;
}
.bottominputandbut svg{
    transform: rotate(180deg);
    color: var(--purplecolor);
    font-size: 22px;
    cursor: pointer;
}

.bloodanalysisfooter{
    width: 100%;
}

@media(max-width:430px){
    .imagesshowdiv img{
        width: 30%;
    }
}

