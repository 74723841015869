
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap');

.iconmaindiv {

    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: row-reverse;
    margin-top: 3rem;
}

.iconmaindiv button {
    /* font-family: "AvenirRegular" ,  'Noto Sans Arabic' , sans-serif ; */
    font-weight: 500;
    font-size: 16px;
    /* padding: 1rem 5rem; */
    width: 121px;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--purplecolor);
    background: transparent;
    outline: none;
    cursor: pointer;
    color: var(--lightblack);
}


.btnandp {
    position: relative;
    top: 0;
    display: none;
}


#searchicon {
    position: absolute;
    top: 15px;
    right: 25px;

}

.maindashboarddiv{
 background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iconmaindiv button.active {
    background-color: var(--purplecolor);
    color: #fff;
}

.btnmaindivbook {
    padding-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: end;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    height: 80px; /* Adjust the height as needed */
      overflow-x: scroll;
}

.btnmaindivbook::-webkit-scrollbar {
    display: none; /* Adjust the height of the scrollbar for horizontal scrolling */
  }

.btnmaindivbook button {
    /* font-family: "AvenirRegular"; */
    font-weight: 500;
    font-size: 16px;
    /* padding: 1rem 2rem; */
    border-radius: 3rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--purplecolor);
    background: transparent;
    outline: none;
    cursor: pointer;
    color: var(--lightblack);
    margin-right: 1rem;
    /* width: 100px; */
    padding-inline: 25px;
    white-space: nowrap;
    padding: 1rem;
}


.btnmaindivbook button.active {
  background-color: var(--purplecolor);
  color: #fff;
}
.btnmaindiv {
    padding-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: end;
    flex-direction: row-reverse;
    width: 90%;
    align-items: center;
    height: 80px; /* Adjust the height as needed */
      overflow-x: scroll;
}

.btnmaindiv::-webkit-scrollbar {
    display: none; /* Adjust the height of the scrollbar for horizontal scrolling */
  }

.btnmaindiv button {
    /* font-family: "AvenirRegular"; */
    font-weight: 500;
    font-size: 16px;
    /* padding: 1rem 2rem; */
    border-radius: 3rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--purplecolor);
    background: transparent;
    outline: none;
    cursor: pointer;
    color: var(--lightblack);
    margin-right: 1rem;
    /* width: 100px; */
    padding-inline: 25px;
    white-space: nowrap;
    padding: 1rem;
}


.btnmaindiv button.active {
  background-color: var(--purplecolor);
  color: #fff;
}
.btnmaindiv {
    padding-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: end;
    flex-direction: row-reverse;
    width: 90%;
    align-items: center;
    height: 80px; /* Adjust the height as needed */
      overflow-x: scroll;
}

.btnmaindiv::-webkit-scrollbar {
    display: none; /* Adjust the height of the scrollbar for horizontal scrolling */
  }

.btnmaindiv button {
    /* font-family: "AvenirRegular"; */
    font-weight: 500;
    font-size: 16px;
    /* padding: 1rem 2rem; */
    border-radius: 3rem;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--purplecolor);
    background: transparent;
    outline: none;
    cursor: pointer;
    color: var(--lightblack);
    margin-right: 1rem;
    /* width: 100px; */
    padding-inline: 25px;
    white-space: nowrap;
    padding: 1rem;
}


.btnmaindiv button.active {
  background-color: var(--purplecolor);
  color: #fff;
}
.videosmaincontierflex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.videosmaincontierflex1{
    display: flex;
    margin: auto;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.videointhedivmain {
    border: 1px solid var(--purplecolor);
    margin: auto;
    background-color: white;
    width: 330px;
    height: 310px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    position: relative;
    gap: 10px;
    overflow: hidden;
}

.videoimageleft {
    width: 100%;
    height: 60%;
    background-size: cover;
}

.vidoedivshow{
    height: 100%;
    /* width: 150px; */
}

.videoimageleft img {
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
}
.videoimageleft video {
    /* width: 100%; */
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.videonamedesright {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
    padding-right: 5px;
    width: 100%;
    height: 40%;
}

.pagination-active{
    padding: 10px;
    border: 1px solid #B571AC;
    width: 30px;
    height: 30px;
    display: flex;
    color: #B571AC;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    transition: 0.2s;
}
.vid-duration{
    margin-right: 15px;
    color: #B571AC;
    padding: 5px;
    background-color: #F9EAF7;
    border-radius: 15px;
    font-size: 11px;
}
.pagination-notactive{
    padding: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DDDDDD;
    border-radius: 5px;
    transition: 0.2s;
}
.pagination-enable{
    padding: 10px;
    width: 25px;
    color: #B571AC;
    height: 25px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: #DDDDDD;
    border-radius: 5px;
    transition: 0.2s;
}
.pagination-disable{
    padding: 10px;
    width: 25px;
    color: whitesmoke;
    cursor: pointer;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #919EAB;
    border-radius: 5px;
    transition: 0.2s;
}
.pagination-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.videonameandicon {
    display: flex;
    gap: 5px;
    justify-content: end;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 16px;
    padding: 0px 16px;
    font-weight: 500;
    font-family: "AvenirRegular";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.videonameandicon div svg {
    cursor: pointer;
}

.videonameandicon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeofvideoup {
    font-size: 14px;
    font-weight: 400;
    font-family: "AvenirRegular";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: var(--purplecolor);
}

.descrationofvideo {
    font-size: 14px;
    font-weight: 400;
    font-family: "AvenirRegular";
    color: #5D5D5D;
    overflow: auto;
    height: 60%;
    text-align: right;
    padding: 0px 16px;
    margin-bottom: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.descrationofvideo::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

/* this is the css for the super admain */

#superadamain {
    padding-right: 0px;
}

#paddingicon {
    padding-right: 60px;
}

.courcesmaindiv{
    width:90%;
    height: 70vh;
    border-radius: 12px;
    background-color: white;
    color: var(--purplecolor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    margin-top: 30px;
}

.imagecardlogo img{
    width: 120px;
    height: 30px;
}

.whatappicon{
    width: 45px;
    height: 45px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 70vh;
    left: 20px;
    position: fixed;
    font-size: 25px;
    cursor: pointer;
    z-index: 1;
}

.dashboardfooter{
    margin-top: 30px;
    width: 100%;
}

/* this is the css of the admain part */

.popupmain{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 50px;
    height: 80px;
    background-color: #D9D9D9;
    border-radius: 6px;
    font-size: 14px;
    position: absolute;
    bottom: 40px;
    right: 12%;
    padding: 5px 0px;
    cursor: pointer;
}
.popupmain1{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 50px;
    height: 80px;
    background-color: #D9D9D9;
    border-radius: 6px;
    font-size: 14px;
    position: absolute;
    bottom: 40px;
    right: 3%;
    padding: 5px 0px;
    cursor: pointer;
}

.popupmain div:nth-of-type(2){
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    width: 100%;
    padding: 5px 0px;
}


.deletemaindiv{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deletecontainer{
width: 250px;
height: 250px;
background-color: #D9D9D9;
border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 10px;
padding: 10px;
color: red;
display: flex;
}
.deletecontainer textarea{
width: 100%;
height: 150px;
background-color: white;
font-size: 16px;
    border-radius: 6px;
    outline: none;
    border: none;
    text-align: end;
    padding-right: 5px;
}
.deletetitle{
font-size: 14px;
font-weight: 600;
display: flex;
justify-content: center;
position: relative;
width: 100%;
}
.CloseHadafPopup{
    font-size: 20px;
    position: absolute;
top: -5px;
right: 5px;
cursor: pointer;
}

.deletecontainer button{
    background-color: red;
   color: white;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
}

.bannerdiv{
    width: 100%;
    margin: auto;
}

@media only screen and (min-width: 900px) and (max-width: 1540px) {
    /* Your styles for laptops */
    .videosmaincontierflex{
        /* flex-wrap: wrap; */
        /* flex-direction: row; */
        align-items: center;
        /* padding-inline: 10px; */
        justify-content: center;
    }
    .videosmaincontierflex1{
        /* flex-wrap: wrap; */
        /* margin: auto; */
        flex-direction: row;
        padding-inline: 10px;
        max-width: 1200px;
        justify-content: end;
    }
    .popupmain{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 50px;
        height: 80px;
        background-color: #D9D9D9;
        border-radius: 6px;
        font-size: 14px;
        position: absolute;
        bottom: 40px;
        right: 12%;
        padding: 5px 0px;
        cursor: pointer;
    }
    .videointhedivmain{
        margin: 0;
        height: 260px;
        width: 283px;
    }
  }

@media(max-width:600px){
    .videonameandicon{
font-size: 14px;
    }
    .timeofvideoup{
font-size: 12px;
    }

    .descrationofvideo{
        font-size: 12px;

    }

}


@media (max-width:430px){
    .videonamedesright {
        text-align: end;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 3px;
        padding-right: 5px;
        font-size: 10px !important;
    }
    .videonameandicon {
        font-size: 12px;
    }
    .timeofvideoup{
        font-size: 10px;
    }
    .descrationofvideo{
        font-size: 10px;
    }
    

.btnandp{
    display: block;
}

.iconmaindiv button {
    width: 90px;
    font-size: 14px;
}
#searchicon {
    position: absolute;
    top: 15px;
    right: 12px;
}

}



