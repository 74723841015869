.unpaid-heading{
    display: flex;
    justify-content: center;
    padding: 20px;
}
.meetings-none{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    margin: auto;
}