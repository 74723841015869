.buyabookmaindiv{
    width: 100%;
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
}

.buyabooknavbar{
width: 100%;
}
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
.buyabookcontainer{
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 90%;
}

.booksectionmian{
display: flex;
flex-direction: column;
gap: 15px;
justify-content: center;
align-items: center;
}

.bookimgdiv{
    padding: 0px;
    height: 280px;
}

.bookimgdiv img{
    width: 370px;
    height: 280px;
    border-radius: 12px 12px 12px 12px;
}
.writtenby{
    font-weight: 300;
}
.author{
    color: rgb(138, 136, 136);
}
.booksectionmian{
    box-shadow: 0px 0px 10px rgba(21, 20, 20, 0.459);
    border-radius: 10px;
    padding-bottom: 10px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.bookinfo{
font-size: 18px;
font-weight: 600;
color: var(--purplecolor);
display: flex;
align-items: center;
}

.bookpopupdiv{
    top: 65%;
    text-align: center;
    padding: 5px 0px;
}

.writtenby{
font-size: 16px;
font-weight: 600;
color: #29292C;
}

.buyers{
    font-size: 16px;
    font-weight: 600;
    color: #29292C;
}
.fivestartsdiv{
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
font-size: 16px;
font-weight: 600;
}
.fivestartsdiv svg{
color: gray;
font-size: 22px;
}

.bookpricediv{
font-size: 16px;
font-weight: 600;
padding: 8px 14px;
color: var(--purplecolor);
border: 1px solid var(--purplecolor);
border-radius: 6px;
cursor: pointer;
font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}

.showmoreauther{
    color: var(--purplecolor);
}

.booknavdivs{
    gap: 20px !important;
}

.buttondivbuybook button{
    background-color: var(--purplecolor);
    color: #fff;
    /* font-family: "AvenirRegular"; */
    font-weight: 500;
    font-size: 1.6rem;
    border: none;
    border-radius: 10px;
    padding: 1rem 2rem;
    cursor: pointer;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}

.buttondivbuybook{
width: 100%;
text-align: center;
}

.buyabookfooter{
width: 100%;
}

.bookaddedclass{
    position: absolute;
    left: 5px;
}
.book-title-adjustment{
    max-width: 240px;
    text-align: center;
}

/* this is the css of the sharepopupbox */

.sharemaindiv{
width: 100%;
height: 100vh;
position: fixed;
top: 0px;
left: 0px;
display: flex;
justify-content: center;
align-items: center;
z-index: 1;
}

.sharecontainer{
width: 320px;
padding: 10px;
height: auto;
display: flex;
flex-direction: column;
gap: 10px;
background-color: white;
border: 1px solid #29292C;
border-radius: 12px;
z-index: 999;
}

.shareinputbutton{
display: flex;
align-items: center;
gap: 5px;
}

.shareinputbutton input{
width: 85%;
height: 35px;
border: 1px solid var(--purplecolor);
border-radius: 6px;
outline: none;
text-indent: 5px;
font-size: 14px;
}

.shareinputbutton button{
padding: 8px 16px;
font-size: 14px;
font-weight: 600;
white-space: nowrap;
border: none;
outline: none;
    background-color: var(--purplecolor);
    color: white;
    border-radius: 6px;
    cursor: pointer;
}



.socialmediaicons{
font-size: 20px;
display: flex;
gap: 10px;
flex-wrap: wrap;
}

.sameall{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}





@media(max-width:430px){
    .book-title-adjustment{
        font-size: 15px;
        max-width: 185px;
        text-align: center;
    }
    .bookimgdiv {
        width: 330px;
        height: 280px;
    }
    .bookimgdiv img{
        width: 330px;
        height: 280px;
    }
    .bookaddedclass{
        position: absolute;
        left: 5px;
    }

}