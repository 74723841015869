.code-visit-code-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 30px 0px;
}
.c-v-c-heading{
    font-size: 16px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    text-align: center;
}
.c-v-c-code{
    font-size: 20px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    text-align: center;
    padding: 5px 20px;
    color: #B571AC;
    background-color: #0000001A;
}
.copy-inform{
    position: absolute;
    top: 10px;
    background-color: #00000080;
    display: flex;
    padding: 5px 10px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    color: white;
}