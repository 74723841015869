.userinfo-container{
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
}
.btns-contnr{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-inline: 5%;
    justify-content: end;
    gap: 10px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  margin-bottom: 20px;
}
.btns-contnr::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  .ui-btn button{
    padding: 10px;
    background-color: white;
    border: 1px solid #B571AC;
    border-radius: 23px;
    cursor: pointer;
    font-size: 15px;
    text-wrap: nowrap;
  }
  .ui-btn-active button{
    padding: 10px;
    background-color: #B571AC;
    border: 1px solid #B571AC;
    color: white;
    border-radius: 23px;
    cursor: pointer;
    font-size: 15px;
    text-wrap: nowrap;
  }
  .ui-branches-container{
    padding-inline: 5%;
  }