.p-m-timing-btns{
    padding: 20px;
}
.p-m-empty-space{
    padding: 10px;
}
.p-m-calender-container{
    display: flex;
    justify-content: center;
    padding: 20px;
}
.p-m-confirm-btn{
    display: flex;
    justify-content: center;
    width: 70%;
    padding: 10px;
    border-top: 1px solid rgb(177, 177, 177);
    margin: auto;
}
.p-m-confirm-btn button{
    padding: 8px 15px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    background-color: #48B865;
    border: none;
    font-size: 15px;
}
.p-d-direction-text{
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}