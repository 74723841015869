

.maindivofinterviewdetails{
    /* height: 100vh; */
    padding-inline: 2rem;
    padding-block: 2rem;
}
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out; /* Adjust the duration and easing as needed */
  }
.policies-in-interview{
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.policies-heading{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.5rem;
    color: #5D5D5DC7;
    text-align: right;
    padding-bottom: 1rem;
}

.interviewtexth2{
padding-block: 2rem;
}

.interviewtexth2 h2{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.5rem;
    color: #5D5D5DC7;
    text-align: right;
    border-bottom: 1px solid #5D5D5D78;
    padding-bottom: 1rem;
}


.meetingdetailtext{
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.meetingdetailtext span{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    padding-block: 1rem;
}

.meetingdetailtext p{
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
    padding-block: 1rem;
}


.descptiontextinterview{
    text-align: right;

}

.descptiontextinterview p{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    color: #5D5D5DC7;

    border-bottom: 1px solid #5D5D5D78;
    padding-block: 1rem;
}

.showthetextareamessage{
    width: 100%;
padding-block: 15px;
    font-weight: 400;
    font-size: 1.6rem;
    font-size: 16px;
    font-weight: 600;
}


.waitingforapprovetext{
    text-align: center  ;
    padding-block: 1rem;
}

.waitingforapprovetext p{
    color: #5D5D5D;
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    border-bottom: 1px solid #5D5D5D78;
    border-top: 1px solid #5D5D5D78;
padding-top: 1rem;
    padding-bottom: 1rem;
}


/* this is the css for the admain approve  */

.admainapprovemain{
display: flex;
flex-direction: column;
gap: 20px;
}

.importantnotesmain{
display: flex;
gap: 10px;
flex-direction: column;
font-size: 16px;
font-weight: 600;
}
.toptitleof{
text-align: end;
border-bottom: 1px solid #5D5D5D;
padding: 10px 0px;
color: #5D5D5DC7;

}
.notesdiv{
text-align: end;
padding-block: 15px;
}
.boublebordertapprove{
border-bottom: 1px solid #5D5D5D;
border-top: 1px solid #5D5D5D;
text-align: center;
padding-block: 10px;
font-size: 16px;
font-weight: 600;
}

.buttondivpaybut{
    margin-block: 20px;
width: 100%;
text-align: center;
}

.buttondivpaybut button{
padding: 8px 18px;
cursor: pointer;
background-color: var(--purplecolor);
color: white;
border: none;
font-weight: 600;
border-radius: 8px;
font-size: 16px;
}

.priceofthemeeting{
text-align: center;
font-size: 16px;
font-weight: 600;
}

.descraptiondivwhole{
    font-size: 14px;
    font-weight: 600;
    text-align: end;
    padding-bottom: 20px;
}

/* this is the css after the meeting is approved */

.approvedmaindiv{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.toptitleof{
font-size: 16px;
font-weight: 600;
}

.descraptiondivofmet{
text-align: end;
font-size: 16px;
font-weight: 600;
}
.descraptiondivofmet div{
    padding-top: 10px;
    padding-right: 10px;
}

.descraptiondivofmet ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.descraptiondivofmet ul li{
    direction: rtl;
    margin-right: 20px;
}

.meetingdetaileng{
display: flex;
flex-direction: column;
gap: 25px;
font-size: 16px;
font-weight: 600;
}

.meettingdeteng{
display: flex;
gap: 10px;
flex-direction: column;
}

.onetapmobile{
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.dailbylocation{

}

.meetingauths{
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.findyoulocalnum{
    display: flex;
    gap: 10px;
    flex-direction: column;
}

/* this is the css for the admain side */

.topbuttonmaindiv{
    display: flex;
    gap: 5px;
    height: 40px;
    background-color: red;
    width: 150px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 0px 4px 0px #00000040;

}


.maindivadmintab{
    text-align: center;
    border-top: 0.37px solid #5D5D5D78;
    margin-top: 20px;
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 600;
}
.maindivadmintab1{
    text-align: center;
    border-top: 0.37px solid #5D5D5D78;
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 600;
}

.givedisplay{
    display: flex;
    gap: 5px;
   justify-content: center;
}

.buttondivindiv{
    width: 70px;
    height: 40px;
    background-color: rgb(164 38 43);
    color:white ;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: auto;
    margin-block: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* this is the css of the popup  */

.showpopupmain{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
}

.showpopupcontainerdiv{
display: flex;
flex-direction: column;
gap: 15px;
width: 250px;
height: 150px;
background-color: white;
justify-content: center;
align-items: center;
border-radius: 8px;
}

.titledivpopup{
color: #E51717;
font-size: 14px;
font-weight: 600;
}

.butondivpopup{
    width: 70px;
    height: 40px;
    background-color: #E51717;
    color:white ;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin: auto;
    margin-block: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputsdivmeetingmain{
display: flex;
flex-direction: column;
gap: 15px;
}

.testareadivflex{
display:flex;
flex-direction: column;
gap: 10px;
width: 100%;
}

.testareadivflex label{
    text-align: end;
    font-size: 14px;
    font-weight: 600;
}
.testareadivflex textarea{
    width: 100%;
    height: 200px;
    border: 0.37px solid #5D5D5D;
    outline: none;
    font-size: 14px;
    text-align: right;
    padding-right: 10px;
    border-radius: 6px;
}
.inputdivflexdiv{
    display:flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.inputdivflexdiv label{
    text-align: end;
    font-size: 14px;
    font-weight: 600;
}
.inputdivflexdiv input{
    width: 100%;
    height: 35px;
    border: 0.37px solid #5D5D5D;
    outline: none;
    font-size: 14px;
    text-align: right;
    padding-right: 10px;
    border-radius: 6px;
}

.buttonsmaindiv2{
display: flex;
gap: 10px;
justify-content: center;
align-items: center;
}

.buttonsmaindiv2 button{
padding: 9px 16px;
border: none;
font-size: 14px;
font-weight: 600;
border-radius: 6px;
cursor: pointer;
}

.buttonsmaindiv2 button:nth-of-type(1){
    background-color: #E51717;
    color: white;
}

.buttonsmaindiv2 button:nth-of-type(2){
    background-color: var(--purplecolor);
    color: white;
}


.meetingpopupmaindiv{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.418);
}

.meetingpopupcontainer{
width: 250px;
height: 250px;
background-color: #F2F2F2;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 10px;
justify-content: center;
align-items: center;
padding: 8px;
}

.meetingpopupcontainer div:nth-of-type(1){
font-size: 14px;
font-weight: 600;
color: #E51717;
}

.meetingpopupcontainer textarea{
    width: 100%;
    height: 150px;
    border: 0.37px solid #5D5D5D;
    outline: none;
    font-size: 14px;
    text-align: right;
    padding-right: 10px;
    border-radius: 6px;
    background-color: white;
    border: none;
}

.meetingpopupcontainer button{
    padding: 9px 16px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    background-color: #E51717;
    color: white;
}