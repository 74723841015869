.sessionexpiremain{
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0px;
left: 0px;
background: #00000066;
z-index: 3;
}

.sessionexpirecontainer{
background: #FFFFFFCC;
height: 265px;
    width: 280px;
border-radius: 8px;
display: flex;
flex-direction: column;
gap: 20px;
padding: 15px;
justify-content: center;
align-items: center;
}

.sessionexpirecontainer div:nth-child(1){
font-size: 20px;
font-weight: 700;
}

.sessionexpirecontainer div:nth-child(2){
    font-size: 16px;
    font-weight: 600;
}

.sessionexpirecontainer div:nth-child(3){
    border-top: 1px solid #3C3C435C;
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.sessionexpirecontainer div:nth-child(3) button{
    font-family: "AvenirRegular";
    font-size: 16px;
    background-color: var(--purplecolor);
    border: none;
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    border: none;

}


