

.maindivofadmin_department{
    height: 100vh;
    background-color: var(--backgroundcolor);
    text-align: end;
    padding-inline: 2rem;
    padding-block: 2rem;
}


.maindivofadmin_department h1{
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.6rem;
    color: var(--purplecolor);
}


.mainflexdivofadmin{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: 2rem;
    margin-top: 1rem;
}

.maindepartmentdiv{
    background-color: var(--purplecolor);
    box-shadow: 2px 4px 4px 0px #00000040;
    border-radius: 1.2rem;
    color: #fff;
    padding: 0.8rem;
    width: 115px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    position: relative;
}

.plusicondivcontainer svg{
font-size: 25px;
font-weight: 700;
cursor: pointer;
color: var(--purplecolor);
}
.plusicondivcontainer{
    width: 30px;
    height: 30px;
    position: relative;
    left: 40%;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maindepartmentdiv h2{
    font-family: "Cairo";
	font-weight: 700;
	font-size: 1.6rem;
    color: #fff;
    text-align: center;
    padding-top: 0.5rem;
}

.insidethemaindiv textarea{
    font-family: "Cairo";
	font-weight: 400;
font-size: 14px;
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
}

#threedoticon {
    cursor: pointer;
    color: var(--graycolor);
}

.plusicondiv{
    text-align: center;
    padding-top: 0.5rem;
    cursor: pointer;
}


.smallpopupdiv{
    position: absolute;
    top: 15px;
    right: 25px;
    width: 80px;
    height: 60px;
    background-color: white;
    border-radius: 8px;
    /* padding: 5px; */
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.smallpopupdiv div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
}
.smallpopupdiv div:nth-child(1){
    border-bottom: 2px solid var(--graycolor);
}


/* this is the css of the delete popup box */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4; /* Ensure it's behind the popup */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

.mainparentdiv {
    display: flex;
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.142);
  }
  .popup-false{
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 6;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .deletedmaindiv {
    background-color: rgba(242, 242, 242, 0.98);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 7;
    align-items: center;
    width: 250px;
    height: 200px;
    margin-top: 3rem;
  }
  
  .deletedmaindiv h1 {
    color: #e51717;
    font-family: "Cairo", sans-serif;
    padding-block: 1rem;
  }
  
  .insidethemaindiv {
    /* padding-inline: 2rem;
    padding-block: 1rem;
    background-color: #ffff;
    width: 95%;
    margin-inline: 2rem;
    text-align: end;
    border-radius: 6px;
    height: 20vh; */
    width: 100%;
    padding-inline: 5px;

  }
  
  .insidethemaindiv textarea {
    width: 100%;
    text-align: right;
    font-family: "Cairo", sans-serif;
    font-weight: 400;
   font-size: 14px;
   height: 15vh;
   background-color: white;
   padding-right: 10px;
    border: none;
    border-radius: 8px;
    outline: none;
  }
  
  .insidethemaindiv h2 {
    font-family: "Cairo", sans-serif;
    font-weight: 400;
    color: var(--graycolor);
  }
  
  .deletediconandtextdiv {
    display: flex;
    gap: 0.5rem;
    background: #e51717;
    color: #fff;
    font-family: "Cairo", sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    margin-block: 1rem;
  }

  /* the add aksam css is here open modal */


 .addmodelmaindiv {
    background-color: #fff;
    border-radius: 2.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 200px;
    padding-inline: 2rem;
  }
  
  .addmodelmaindiv h1 {
    color: black;
    text-align: right;
    font-family: "Cairo", sans-serif;
    padding-block: 1rem;
  }

  .addmodelmaindiv input{
    border: 1px solid var(--purplecolor);
    background-color: #F5F5F5;
    padding: 1.5rem;
    border-radius: 1.4rem;
    width: 100%;
    text-align: right;
    outline: none;

  }

  .addmodalbtndiv{
    background-color: var(--purplecolor);
    color: #fff;
    border-radius: 4px;
    padding: 1rem;
    font-size: 14px;
    margin-top: 1.5rem;
    cursor: pointer;
  }
  
  


  @media screen and (max-width:500px) {
    .mainparentdiv {
    padding-inline: 20px;
    }

    .deletedmaindiv {
        background-color: rgba(242, 242, 242, 0.98);
        border-radius: 2.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 28vh;
        padding: 5px;
        margin-top: 3rem;
    }

.addmodelmaindiv{
    width: 100%;
}

  }