

.arrowmaindiv{
    background: #fafafa;
    color: var(--blackcolor);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 2rem;
    position: relative;
    margin-top: 1rem;
    border-top: 1px solid var(--purplecolor);
    border-bottom: 1px solid var(--purplecolor);
    padding-inline: 10px;
    cursor: pointer;
}


.arrowmaindiv h1{
    font-family: "Cairo";
    font-weight: 700;
    font-size: 2.5rem;
}


.leftarrow{
    position: absolute;
    left: 0;
}

.threedotsdiv{
    position: absolute;
    right: 0;

}

.mainnocatageries{
    font-size: 14px;
    font-weight: 500;
    color: var(--purplecolor);
}

.maindepartmentdiv2{
    width: 120px;
    height: 60px;
    background-color: var(--purplecolor);
    border-radius: 6px;
    text-align: center;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-block: 7px;
}
