.sa-userprofile-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px;
}
.sa-up-p-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    gap: 5px;
}
.sa-up-pd-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    gap: 5px;
    font-size: 18px;
}
.sa-up-pd-img img{
    width: 110px;
    height: 110px;
    border-radius: 50%;
}
.sa-up-details{
    width: 95%;
}
.sa-up-d-heading{
    width: 100%;
    text-align: right;
    font-size: 15px;
    color: #B571AC;
    font-weight: 500;
    border-bottom: 1px solid #5D5D5D78;
    padding: 12px;
    margin-bottom: 5px;
}
.sa-up-d-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 15px;
}
.sa-up-d-sec1{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
    border-bottom: 1px solid #00000024;
    width: 100%;
    padding: 10px;
}
.sa-up-d-sec2{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
    width: 100%;
    padding: 10px;
}
.details-direction{
    color: #5D5D5D;
    font-weight: 400;
}