.codes-container{
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
}
.codes-header{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
    background-color: white;
    border-bottom: 1px solid #A85BB0;
    border-top: 1px solid #A85BB0;
    font-size: 18px;
    font-weight: 700;
    position: relative;
}
.code-copied{
    position: fixed;
    background-color: #5d5d5d8f;
    color: white;
    font-size: 20px;
    top: 50px;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 6;
}
.codes-header-icon{
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 20px;
    cursor: pointer;
}
.codes-data-container{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
}
.code-data{
    display: flex;
    flex-direction: column;
    width: 300px;
}
.code-data-upper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FBE5FF;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    position: relative;
}
.code-data-upper-right-ball{
    position: absolute;
    top: 45%;
    right: -8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: whitesmoke;
}
.code-data-upper-left-ball{
    position: absolute;
    top: 45%;
    left: -8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: whitesmoke;
}


.code-data-upper-logo{
    padding: 40px 20px;
    border-left: 2px dashed white;
}
.code-data-upper-code{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    font-family: "Cairo", sans-serif;
    color: #A85BB0;
    
}
.code-data-lower{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    align-items: center;
    text-align: center;
    border: 1px solid #A85BB0;
    border-radius: 0px 0px 10px 10px;
    position: relative;
    background-color: white;
}
.code-data-lower-occassion{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.code-data-lower-occassion-date{
    color: #A85BB0;
}
.code-data-lower-occassion-dots{
    color: #B571AC;
    position: absolute;
    right: 2px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
}
.code-data-lower-discount-rate{
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 5px;
}
.code-data-lower-dates{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}
.code-data-lower-dates-date{
    display: flex;
    gap: 5px;
    align-items: center;
}
.code-data-lower-dates-date-date{
    color: #B571AC;
    background-color: #FBE5FF;
    padding: 5px;
    border-radius: 23px;
}
.code-data-lower-status{
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 5px;
    font-weight: 700;
    color: #B571AC;
    align-items: center;
}
.code-data-lower-status-toggle{
    position: relative;
    width: 25px;
    height: 15px;
    border-radius: 30px;
    background-color: #B571AC;
    cursor: pointer;
}
.code-data-lower-status-toggle-inactive{
    position: relative;
    width: 25px;
    height: 15px;
    border-radius: 30px;
    background-color: #5D5D5D;
    cursor: pointer;
}
.code-data-lower-status-toggle-circle{
    position: absolute;
    top: 2px;
    right: 2px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: white;
}
.code-data-lower-status-toggle-circle-inactive{
    position: absolute;
    top: 2px;
    left: 2px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: white;
}
.code-data-lower-button button{
    font-size: 14px;
    color: white;
    background-color: #A85BB0;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
}
.code-data-lower-status-gher{
    color: #5D5D5D;
}


.code-data-lower-occassion-popup-hunter{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
}
.code-data-lower-occassion-popup{
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 6;
}
.code-data-lower-occassion-popup-edit{
    padding: 10px; 
    cursor: pointer;
}
.code-data-lower-occassion-popup-delete{
    padding: 10px; 
    cursor: pointer;
}
.code-data-lower-occassion-popup-line{
    width: 100%;
    border-bottom: 1px solid #5D5D5D85;
}