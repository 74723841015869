
  .swiper-pagination-bullet-active{
    background-color: var(--purplecolor);
  }

  .widthswiper{
    width: 100%;
    height: 300px;
    position: relative;
  }
  .minloadingdiv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.29); */
    background-color: #0000003b;
    z-index: 3;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minloadingdiv svg{
    font-size: 50px;
    color: var(--purplecolor);
  }

.swiper-slide {
    width: 100%;
    height: 300px;
}
.mainswiperdiv{
width: 100%;
height: auto;
max-height: 310px;
position: relative;
display: flex;
justify-content: center;
align-items: center;
}
.mainswiperdiv img{
width: 100%;
height: 310px;
object-fit: contain;
}

.positionabsolutediv{
position: absolute;
top: 50%;
right: 40px;
font-size: 18px;
font-weight:600;
color: white;
}

.descraptionabso{
    width: 100%;
    background: #B571ACAD;
    position: absolute;
    bottom: 0px;padding: 25px 10px;
    text-align: end;
    font-size: 14px;
    font-weight: 600;
    color: white;
}

.threedots{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    cursor: pointer;
    font-weight: 600;
    color: var(--purplecolor);
}

.givingtopvalue{
    top: 80%;
}

.swiper-pagination{
    /* position: absolute !important;
    bottom: -10px !important; */
}

