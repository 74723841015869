.discount-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
    background-color: whitesmoke;
    position: relative;
}
.discount-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.discount-body-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 6px;
}
.discount-form-div{
    width: 100%;
    border: 1px solid #B571AC;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
}
.discount-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.discount-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.discount-input{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    align-items: end;
    gap: 5px;
}
.discount-button{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
}
.discount-expiration-date-container{
    display: flex;
    gap: 18px;
    font-size: 14px;
}
.discount-expiration-date-container div{
    display: flex;
    align-items: center;
    gap: 8px;
}
.discount-input input{
    width: 100%;
    border: 1px solid var(--purplecolor);
    border-radius: 10px;
    outline: none;
    text-indent: 10px;
    padding: 8px;
    font-size: 14px;
    direction: rtl;
    font-family: "Cairo", sans-serif;
}
.discount-input label{
    font-size: 14px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}
.discount-heading{
    display: flex;
    justify-content: center;
    width: 100%;
}
.discount-heading h1{
    font-size: 20px;
    font-weight: 700;
    font-family: "Cairo", sans-serif;
    color: #B571AC;
}
.discount-header-icon{
    font-size: 25px;
    cursor: pointer;
}
.discount-header-title{
    font-size: 18px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    color: #B571AC;
}
.discount-button button{
    font-size: 14px;
    color: white;
    background-color: #B571AC;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}

@media (max-width: 899px) {
    /* styles for screens with a width of less than 900 pixels */
    .discount-body-inner {
      width: 100%;
    }
  }