@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}

/* @media only screen and (-webkit-min-device-pixel-ratio: 0) {
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
} */

/* body{
  overflow-x: hidden !important;
  user-select: none;
} */

:root{
  --purplecolor : #B571AC;
  --redcolor : #E30101;
  --backgroundcolor: #FAFAFA;
  --whitecolor: #FFFFFF;
  --fontcolor : #29292C;
  --lightblack: #1D293F;
  --graycolor : #848383;
}


html{
  font-size: 62.5%;
}

@font-face {
  font-family: "OldAnticDecorativeRegular";
  src: url('./Asserts/Fonts/OldAnticDecorativeRegular/OldAnticDecorativeRegular.ttf');
  font-family: "OldAnticDecorativeRegular", serif; /* Fallback to serif */
}

@font-face {
  font-family: "avenirarabicmedium";
  src: url('./Asserts/Fonts/avenirarabicmedium.ttf');
  font-family: "avenirarabicmedium", Arial, sans-serif; /* Fallback to Arial or sans-serif */
}

@font-face {
  font-family: "AvenirRegular";
  src: url('./Asserts/Fonts/Avenir/AvenirRegular.ttf');
  font-family: "AvenirRegular", Helvetica, sans-serif; /* Fallback to Helvetica or sans-serif */
}

@font-face {
  font-family: "AvenirLight";
  src: url('./Asserts/Fonts/Avenir/AvenirLight.ttf');
  font-family: "AvenirLight", Helvetica, sans-serif; /* Fallback to Helvetica or sans-serif */
}

@font-face {
  font-family: "AvenirHeavy";
  src: url('./Asserts/Fonts/Avenir/AvenirHeavy.ttf');
  font-family: "AvenirHeavy", Helvetica, sans-serif; /* Fallback to Helvetica or sans-serif */
}

@font-face {
  font-family: "Cairo";
  src: url('./Asserts/Fonts/Cairo.ttf');
  font-family: "Cairo", Arial, sans-serif; /* Fallback to Arial or sans-serif */
}

.testfontfamily{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
  /* font-family: "Noto Sans Arabic", sans-serif; */
}

.loadingmaindiv{
  top: 0px;
  left: 0px;
  color: var(--purplecolor);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}


