.book-discount-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0px;
}
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

.book-discount-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 50%;
}
.book-discount-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}
.book-discount-header-icon {
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    left: 0px;
}
.book-discount-header-text {
    font-size: 18px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}
.book-discount-lower{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    gap: 20px;
    border: 1px solid #B571AC;
    border-radius: 10px;
    background-color: white;
}
.book-discount-lower-book{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    background-color: whitesmoke;
    padding: 10px;
    border-radius: 5px;
}
.book-discount-lower-book-details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.book-discount-lower-book-details-text{
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    justify-content: center;
    align-items: end;
    padding: 20px;
    font-size: 16px;
    font-family: "Cairo", sans-serif;
}
.book-discount-lower-book-details-img img{
    width: 160px;
    height: 210px;
}
.book-discount-lower-book-details-text-title{
    font-weight: 700;
    font-family: "Cairo", sans-serif;
    color: #B571AC;
}
.book-discount-lower-book-details-text-author{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
}
.book-discount-lower-book-details-text-author-name{
    font-family: "Cairo", sans-serif;
    color: #6B7280;
}
.book-discount-lower-book-line{
    width: 100%;
    height: 1px;
    background-color: #5D5D5D30;
}
.book-discount-lower-book-price{
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    font-size: 16px;
}
.book-discount-lower-code{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
    font-family: "Cairo", sans-serif;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    background-color: whitesmoke;
    border-radius: 5px;
}
.book-discount-lower-input{
    display: flex;
    width: 100%;
}
.book-discount-lower-input input{
    width: 100%;
    direction: rtl;
    border-radius: 5px;
    border: 1px solid #B571AC;
    padding: 6px;
    font-size: 16px;
    font-weight: 600;
}
.book-discount-lower-button{
    display: flex;
    justify-content: center;
    width: 200px;
}
.book-discount-lower-button button{
    font-size: 14px;
    color: white;
    background-color: #B571AC;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
}
.book-discount-lower-next{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.book-discount-lower-next button{
    font-size: 14px;
    color: white;
    background-color: #B571AC;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
}

.book-discount-lower-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
}

.book-discount-lower-discount{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.book-discount-lower-discount-text{
    display: flex;
    gap: 5px;
}


@media (max-width: 820px) {
    /* styles for screens with a maximum width of 768px */
    .book-discount-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
    .book-discount-container {
        padding: 40px 20px;
  }
  .book-discount-lower-code{
    flex-wrap: wrap;
  }
  .book-discount-lower-book-details{
    flex-wrap: wrap;
    justify-content: center;
  }
}