.mynav {
  width: 100%;
  /* padding-block: 20px; */
  height: 60px;
  padding-inline: 20rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9eaf7;
  font-size: 3rem;
  font-weight: 400;
  font-family: "KanYaMaKanFreeRegular";
  position: relative;
}

.navlog {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  cursor: pointer;
}
.navlog img {
  /* width: 100px; */
  height: 40px;
  margin-top: 1rem;
}
.navlog h2 {
  font-size: 3rem;
  font-weight: 400;
  font-family: "OldAnticDecorativeRegular";
  color: var(--purplecolor);
  white-space: nowrap;
}

.crosicon {
  position: absolute;
  top: 0px;
  right: 0px;
  /* display: none; */
  background-color: white;
  color: black;
  font-size: 12px;
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: none;
}

.inpbox {
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; */
}

.inpbox {
  position: relative;
}

.myicon {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 22px;
  /* z-index: -1; */
}

.myicon svg {
  width: 2rem;
}

.inpbox input {
  width: 900px;
  text-align: end;
  padding-block: 10px;
  border-radius: 20px;
  border: none;
  padding-right: 50px;
  font-size: 16px;
  outline: none;
  background: #ffffff;
  color: var(--graycolor);
}

.serchpopupbox {
  width: 70%;
  text-align: end;
  /* padding-block: 10px; */
  border-radius: 20px;
  border: none;
  font-size: 1.6rem;
  outline: none;
  background: #ffffff;
  border: 1px solid gray;
  color: var(--graycolor);
  display: flex;
  height: 40px;
  position: relative;
}

.dropdownicon select {
  width: 120px;
  height: 37px;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 5px;
  border-radius: 20px 0px 0px 20px;
  border-right: 1px solid #29292c !important;
  outline: none;
  font-family: "Cairo", sans-serif;
  font-size: 18px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  /* text-align: left; */
  margin-left: 15px;
  direction: rtl; /* Reverse direction for right-to-left */
  /* appearance: none; */
}

.dropdownicon select option {
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  border-radius: 8px !important;
  text-align: right;
}

.inpbox input::placeholder {
  color: black;
  font-family: "Cairo";
  font-weight: 600;
  font-size: 1.5rem;
}

.btnbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btnbox .b1 {
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 400;
  font-family: "KanYaMaKanFreeRegular";
  border-radius: 8px;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.463);
  border: 1px solid var(--purplecolor);
  background-color: transparent;
  cursor: pointer;
}

.btnbox .b2 {
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 400;
  font-family: "KanYaMaKanFreeRegular";
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  border: 1px solid var(--purplecolor);
  background-color: var(--purplecolor);
  cursor: pointer;
}

.maindivnoti{
position: relative;
display: flex;
justify-content: center;
align-items: center;
}
.show123{
position: absolute;
top: 0px;
width: 15px;
right: 0px;
font-size: 12px;
height: 15px;
background: red;
color: white;
display: flex;
border-radius: 50%;
font-weight: 600;
justify-content: center;
align-items: center;
}



.navicons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid wheat;
  width: 200px;
  height: 100%;
  padding: 10px 50px;
}

.myb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.myb svg {
  /* color:  #1D293F; */
  color: var(--purplecolor);
  width: 4rem;
  height: 3rem;
  cursor: pointer;
}

.bbtt {
  /* display: none; */
  display: flex;
  gap: 10px;
  align-items: center;
}

.searchicon {
  display: none;
}

/* this is the css of the side bar */

.mainnotifyshowdiv{
  border: 1px solid #B571AC;
/* flex-direction: column; */
}

.dateandtimenotify{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  font-size: 14px;
  font-weight: 600;
  color: #B571AC;
}


.sidebarmainusad {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  top: 0px;
  font-family: "Cairo", sans-serif;
  right: 0px;
  color: black;
  transition: 0.5s;
  display: flex;
  justify-content: end;
  /* padding: 5px; */
  z-index: 6;
  /* overflow-y: scroll; */
}

/* WebKit browsers (Chrome, Safari, Edge) */
.sidebarcontainer::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.sidebarcontainer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.sidebarcontainer::-webkit-scrollbar-thumb {
  background: var(--purplecolor); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

.sidebarmainusad::-webkit-scrollbar-thumb:hover {
  background: var(--graycolor); /* Color of the scrollbar thumb when hovered */
}

.sidebarmainusad2 {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  color: black;
  position: fixed;
  top: 0px;
  font-family: "Cairo", sans-serif;
  right: -100%;
  display: flex;
  justify-content: end;
  transition: 0.5s;
  padding: 5px;
  z-index: 6;
}

.sidebarcontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 230px;
  height: 100vh;
  align-items: center;
  background-color: white;
  position: fixed;
  overflow-y: scroll;
}

.sidebarmaindivnew {
  height: 100vh;
  background-color: white;
  width: 230px;
}



.superadmainside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* gap: 10px; */
  font-size: 20px;
  font-weight: 400;
  padding-top: 10px;
  border-bottom: 2px solid black;
  width: 100%;
  padding-bottom: 10px;
}
.user-profile-pic img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
}

.crossiconforshut {
  position: absolute;
  left: 5px;
  top: 20px;
  color: black;
  font-size: 14px;
  cursor: pointer;
}

.superadmainroutes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid black;
  padding-bottom: 20px;
}

/* this is for the active super admain routes */

.superadmainroutes a.active,
.settingsandlogout a.active,
.superadmainroutes a.active {
  background-color: var(--purplecolor);
  text-decoration: none;
  border-bottom: none;
  padding: 0px;
  width: 95%;
  color: white;
  padding: 5px;
  /* padding-right: 10px; */
  border-radius: 12px;
  height: 40px;
}

.superadmainroutes a,
.settingsandlogout a,
.superadmainroutes a {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  /* padding-right: 10px; */
  color: black;
  height: 40px;
  cursor: pointer;
}

.settingshowdiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
}

.settingshowdiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.settingshowdiv.visible {
  max-height: 1000px;
  transition: max-height 0.5s ease;
}
.superadmainroutes div {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}
.superadmainroutes div {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
}

.settingsandlogout {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
}

.giveheight {
  height: 40px;
}
.settingsandlogout div {
  position: relative;
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
}

.dropdowniconf {
  position: absolute !important;
  left: 0px;
  width: 30px !important;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

/* this is the code of the notification pupup */
.changeingdivmain {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 300px;
  transition: 1s;
  z-index: 2;
  display: none;
}
.changeingdivmain2 {
  position: absolute;
  top: 80px;
  left: 40px;
  width: 300px;
  transition: 1s;
  z-index: 2;
}

.popupmaindiv {
  width: 300px;
  background-color: white;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
  flex-direction: column;
  border-radius: 12px;
  color: black;
  padding: 10px;
 height: 200px;
  overflow-y: scroll;
}
.nonotificationyet{
text-align: center !important;
  font-size: 16px !important;
  background-color: transparent !important;
  justify-content: center !important;
  color: var(--purplecolor);
}

.popupmaindiv::-webkit-scrollbar {
  width: 2px; /* For Chrome, Safari, and Edge */
}

.popupmaindiv::-webkit-scrollbar-thumb {
  background-color: var(--purplecolor); /* Scrollbar color */
  border-radius: 10px; /* Rounded scrollbar thumb */
}

.popupmaindiv::-webkit-scrollbar-track {
  background: transparent; /* Scrollbar track color */
}


.mainnotifyshowdiv {
  width: 100%;
  /* height: 40px; */
  font-size: 14px;
  font-weight: 600;
  /* text-align: end; */
  /* background-color: var(--graycolor); */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 5px;
  gap: 5px;
  /* padding: 10px; */
}

.nav-mid-btns{
  display: none;
}
@media only screen and (max-width: 1500px) {
  .mynav {
    width: 100%;
    padding-block: 20px;
    padding-inline: 7rem !important;
  }

  .inpbox input {
    width: 625px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1025px) {
  .mynav {
    width: 100%;
    padding-block: 20px;
    padding-inline: 6rem !important;
  }

  .inpbox input {
    width: 350px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1540px) {
  /* Your styles for laptops */
  .meeting-for-user-in-nav{
    display: none;
  }
  .btnbox .b1 {
    outline: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "KanYaMaKanFreeRegular";
    border-radius: 23px;
    padding: 8px 16px;
    color: white;
    border: 1px solid black;
    background-color: black;
    cursor: pointer;
  }
  .nav-mid-btns{
    display: flex;
    gap: 25px;
    font-size: 18px;
    padding-left: 124px;
    font-family: "AvenirHeavy";
    font-weight: 200;
  }
  .nav-mid-btns div{
    cursor: pointer;
  }
  .nav-mid-btns-btn-active{
    color: var(--purplecolor);
  } 
  .btnbox .b2 {
    outline: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "KanYaMaKanFreeRegular";
    border-radius: 23px;
    padding: 8px 16px;
    color: white;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    cursor: pointer;
  }
}

@media only screen and (max-width: 786px) {
  .mynav {
    width: 100%;
    padding-block: 20px;
    padding-inline: 2rem !important;
  }

  .inpbox input {
    display: none;
    /* font-size: 16px; */
  }

  /* .myb {
    display: none;
  } */

  .bbtt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 500px) {
  .mynav h2 {
    font-size: 2.5rem;
    padding-inline: 0rem !important;
  }

  .navlog img {
    width: 40px;
    height: 35px;
  }

  .searchicon {
    display: block;
  }

  .b1 {
    padding: 5px 10px;
  }

  .b2 {
    padding: 5px 10px;
  }

  .myb {
    display: flex;
  }

  /* .btnbox .b2 {
    font-size: 1.7rem;
    padding: 11px 19px;
  } */


  .mynav {
    width: 100%;
    /* padding-block: 20px; */
    height: 60px;
    padding-inline: rem !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .navlog img {
    width: 25px;
    height: 25px;
  }
  .navlog{
    gap: 10px;
  }
  .dropdownicon select {
    width: 100px;
    margin-left: 7px;
    padding-left: 2px;
    padding-inline: 0px;
  }
  .serchpopupbox {
    width: 85%;
  }
  .searchicon {
    display: block;
  }

  .inpbox input {
    display: block;
    width: 80%;
    font-size: 16px;
    /* margin-top: 10px; */
  }

  .myicon {
    display: block;
    position: absolute;
    /* top: 16px;
		right: 25%; */
    /* z-index: 1; */
  }

  .myicon svg {
    display: block !important;
  }

  .inpbox {
    display: block;
    width: 100%;
    /* background-color: red; */
    position: absolute;
    top: 7%;
    left: 0px;
    background-color: #fff;
    height: 70px;
    transition: 0.5s;
    z-index: 1;
    /* padding: 10px; */
  }

  .crosicon {
    display: flex;
    position: absolute;
    top: 16px;
    right: 5%;
  }

  /* this si for the drop down of the search */

  .inpbox {
    max-height: 0px;
    overflow: hidden;
  }

  .inpbox.visible {
    max-height: 1000px;
    position: absolute;
    top: 155px;
    left: 0px;
    padding: 10px;
    z-index: 1;
    transition: 0.5s;
  }

  .bbtt {
    /* display: none; */
  }

  .myb {
    display: flex;
  }

  .mynav {
    height: 60px;
  }

  .navlog {
    gap: 15px;
    font-size: 16px;
  }

  .myb svg {
    /* color:  #1D293F; */
    color: var(--purplecolor);
    width: 3.5rem;
    height: 2.5rem;
    cursor: pointer;
  }

  .mynav {
    width: 100%;
    /* padding-block: 20px; */
    height: 60px;
    padding-inline: 1rem !important;
  }

  .btnbox .b1 {
    padding: 8px 17px;
  }

  .btnbox .b2 {
    padding: 9px 23px;
  }

  .b2 {
    padding: 5px 6px;
  }

  .navlog h2 {
    font-size: 2.5rem;
  }
}

.usersidebarlist{
  left: 20px;
  display: flex;
  transition: all .5s ease;
}


.userssidebar {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  top: 0;
  font-family: "Cairo", sans-serif;
  right: 0px;
  color: black;
  transition: 0.5s;
  display: flex;
  justify-content: end;
  /* padding: 5px; */
  z-index: 6;
  /* overflow-y: scroll; */
}
.userssidebar2 {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  color: black;
  position: fixed;
  top: 0;
  font-family: "Cairo", sans-serif;
  right: -100%;
  display: flex;
  justify-content: end;
  transition: 0.5s;
  padding: 5px;
  z-index: 6;
}
.closing-navbar{
  height: 100vh;
  width: 100%;
}
.UserActiveStts {
  display: flex;
  align-items: center;
  margin-top: 17px;
  font-size: 22px;
}

