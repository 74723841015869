.webinar-attendant-heading{
    padding: 10px;
    position: relative;
    border-bottom: 1px solid #B571AC;
    border-top: 1px solid #B571AC;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.w-a-h-icon{
    position: absolute;
    left: 20px;
    font-size: 20px;
    top: 25px;
}
.w-a-heading-text{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.w-a-h-t-about-webinar{
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
.w-a-h-t-a-w-downup{
    color: #B571AC;
}
.w-a-h-t-downer{
    font-size: 14px;
    color: #B571AC;
    font-weight: 500;
}
.w-a-search{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
}
.w-a-bodie{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: whitesmoke;
}
.w-a-search-head{
    font-size: 14px;
    font-weight: 600;
    color: #B571AC;
}
.w-a-search-outer{
    width: 100%;
}
.w-a-search-inner{
    width: 100%;
}
.w-a-attendants-outer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.w-a-attendants-inner{
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 12px;
}
.w-a-a-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 10px;
    gap: 15px;
    font-size: 13px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #B571AC;
}
.w-a-a-b-date{
    font-size: 15px;
    color: #B571AC;
    font-weight: 500;
}
.w-a-a-b-main-info{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    border-bottom: 1px solid #5D5D5D30;
    padding-bottom: 14px;
}
.w-a-a-b-mi-infodiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}
.w-a-a-b-questions{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
    gap: 4px;
}
.w-a-dd-detail{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    font-size: 14px;
    gap: 10px;
}
.w-a-dd-d-80{
    width: 100%;
    text-align: end;
    border-bottom: 2px solid #5d5d5d;
}
.w-a-dd-d-20{
    width: 75px;
    text-align: left;
    color: #B571AC;
    font-weight: 500;
    text-align: start;
}
.w-a-dd-container{
    width: 100%;
    gap: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}