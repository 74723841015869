
/* 
.mainverificationdiv{
    height: 75vh;
} */


.mainverification{
    /* margin: 0 auto; */
    width: 90%;
    /* height: 60vh; */
    max-width: 400px;
    background-color: #FFFFFF;
    /* margin-block: 8rem; */
    box-shadow: 6.32px 6.32px 11.59px 3.16px #00000040;
    border-radius: 3rem;
    text-align: center;
    padding-block: 2rem;
    position: relative;
}


.mainverification h1{
    font-size: 2.5rem;
    font-weight: 700;
    font-family: "Cairo", sans-serif; 
}


.mainverification p{
    font-size: 1.7rem;
    font-weight: 600;
    font-family: "Cairo", sans-serif; 
    padding-inline: 2.2rem;
    text-align: end;
    padding-block: 3rem;
}


.verificationcode{
    text-align: end;
    padding-inline: 1.8rem;
}


.verificationcode h2{
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Cairo", sans-serif; 
    padding-bottom: 0.5rem;
}


/* Verification.css */

.mainverificationdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #f5f5f5; /* Light background color */
}




.verification-inputs {
    display: flex;
    justify-content: space-between; /* Space between the input fields */
    margin-bottom: 20px; /* Space below the inputs */
    gap: 0.6rem;
}

.verification-inputs input {
    width: 55px; /* Width of each input */
    height: 47px; /* Height of each input */
    text-align: center; /* Center text alignment */
    font-size: 24px; /* Larger font size */
    border: 1px solid #5D5D5D; /* Light border */
    border-radius: 14px; /* Rounded corners */
    font-family: "Cairo", sans-serif; /* Font family */
    box-shadow: 0px 0px 10.54px 0px #00000040;
    outline: none;
    background-color: #F5F5F5;
    font-size: 16px;
}

/* .verification-inputs input:focus {
    border-color: #00000040; 
    outline: none; 
    box-shadow: 0 0 5px #00000040; 
} */

.verifybutton {
    display: flex;
    justify-content: center; /* Center the button */
}

.verifybutton button {
    padding: 10px 20px;

    border: none; 
    border-radius: 14px; 
    background-color: var(--purplecolor); 
    color: white; 
    cursor: pointer; 
    width: 100%;
    font-size: 1.7rem;
    font-weight: 700;
    font-family: "Cairo", sans-serif; 
}


.verificationlastpdiv h3{
    font-weight: 600;
    font-family: "Cairo", sans-serif; 
    font-size: 1.5rem; 
    padding-block: 1rem;
}
.verificationlastpdiv h4{
    font-weight: 600;
    font-family: "Cairo", sans-serif; 
    font-size: 1.5rem; 
}
.verificationlastpdiv span{
    font-weight: 600;
    font-family: "Cairo", sans-serif; 
    font-size: 1.5rem; 
    color: #34B7F1;
    cursor: pointer;
}


@media (max-width:430px){
    .mainverificationdiv{
        height: 63vh;
    }
    .mainverification{
        /* height: 55vh; */
        padding-block: 1rem;
    }

    .mainverification p {
        padding: 10px;
        font-size: 1.2rem;
    }

.veriffooter{
    /* position: absolute; */
    bottom: 0px;
    width: 100%;
}
.verification-inputs input {
    width: 48px;
    font-size: 16px;
    font-size: 16px;
}
.verification-inputs{
    margin-bottom: 10px;
}
}
