

.maindivofprofile{
    /* height: 100vh; */
    position: relative;
}

.mainflexdivphotoandcontent{
    display: flex;
    padding-block: 3rem;
}

.maindivofprofile{
    margin: 0 auto;
    width: 90%;
    max-width: 800px;
    background-color: #FFFFFF;
    margin-block: 8rem;
    box-shadow: 6.32px 6.32px 11.59px 3.16px #00000040 !important;
    border-radius: 3rem;
}


.profiledatadiv h1{
    font-size: 2.9rem;
    font-family: "Cairo";
    font-weight: 700;
    text-align: center;
    padding-top: 3rem;
          font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;

}


.inputprofilemaindiv1{
    text-align: end;
    padding-inline: 2rem;
    padding-top: 2rem;
    width: 100%;
}
.radio-gender{
  padding: 10px;
}
.radio-inputs{
  display: flex;
  justify-content: end;
  gap: 20px;
}
.radio-field{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.inputprofilemaindiv1 label{
    font-size: 1.5rem;
    font-family: "AvenirLight";
    font-weight: 400;
}


.signup-input input{
  padding-right: 10px;
    /* padding: 1.3rem; */
    font-size: 13px;
    width: 100%;
    box-shadow: 0px 0px 10.54px 0px #00000040;
    /* padding-top: 1rem; */
    height: 40px;
    border-radius: 1.4rem;
    border: 1px solid #5D5D5D;
    text-align: right;
    outline: none;
    background: #F5F5F5;
    margin-bottom: 1rem;
    font-family: "Cairo";
    font-weight: 600;
    margin-top: 0.7rem;
}


.mainselectdiv{
    display: flex;
    justify-content: end;
    gap: 2rem;
}

.datemaindiv{
    display: flex;
    gap: 2rem;
}

.datemaindiv input::placeholder {
    text-align: center; /* Center align the placeholder text */
}

.dropdownmaindiv {
    position: relative;
    display: flex;
    justify-content: flex-end; /* Align items to the right */
  
  }
  
  .select-box {
    text-align: end;
    font-size: 1.4rem;
    font-family: "Cairo";
    font-weight: 600;
    background-color: #9C9C9C;
    border-radius: 1.6rem;
    color: black;
    cursor: pointer;
    padding: 1.3rem 2rem;
    padding-inline: 4rem;
    position: relative;
    border: none;
    outline: none;
    width: 175px;
    /* display: flex; */

  }
  
  .select-box::after {
    content: '▼';
    font-size: 1.5rem;
    color: #7A7777;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; /* Set width of options */
    background-color: var(--blackcolor);
    border-bottom-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
    border-radius: 0.5rem;
    margin-top: -1.1rem;
    transition: max-height 0.3s ease;
    overflow: hidden;
  }
  
  .select-box.open .options {
    display: block;
    max-height: 200px; /* Adjust as needed */
  }

  
  
  .option {
    font-size: 1.4rem;
    font-family: "Cairo";
    font-weight: 600;
    color: #fff;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: rgba(52, 183, 241, 0.2);
  }



  
.dropdownmaindiv2 {
    position: relative;
    display: flex;
    justify-content: flex-end; /* Align items to the right */
  }
  
  .select-box2 {
    text-align: end;
    font-size: 1.4rem;
    font-family: "Cairo";
    font-weight: 600;
    background-color: var(--blackcolor);
    border-radius: 1.6rem;
    color: #fff;
    cursor: pointer;
    padding: 1.3rem 2rem;
    padding-inline: 4rem;
    position: relative;
    border: none;
    outline: none;
    width: 175px;
    /* display: flex; */

  }
  
  .select-box2::after {
    content: '▼';
    font-size: 1.5rem;
    color: #34B7F1;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .options2 {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%; /* Set width of options */
    background-color: var(--blackcolor);
    border-bottom-left-radius: 1.4rem;
    border-bottom-right-radius: 1.4rem;
    border-radius: 0.5rem;
    margin-top: -1.1rem;
    transition: max-height 0.3s ease;
    overflow: hidden;
  }
  
  .select-box2.open .options2 {
    display: block;
    max-height: 200px; /* Adjust as needed */
  }

  
  
  .option2 {
    font-size: 1.4rem;
    font-family: "Cairo";
    font-weight: 600;
    color: #fff;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  
  .option2:hover {
    background-color: rgba(52, 183, 241, 0.2);
  }
  
  
  

.profilebtndiv{
    text-align: center;
}

.profilebtndiv button{
    width: 100%;
    background-color: var(--purplecolor);
    padding: 1rem;
    border: none;
    outline: none;
    border-radius: 1.4rem;
    cursor: pointer;
    color: #fff;
    font-size: 1.7rem;
    font-family: "Cairo";
    font-weight: 700;
    /* margin-block: 1rem; */
    height: 45px;
}
  

.profilebtndiv h3{
    font-size: 1.5rem;
    font-family: "Cairo";
    font-weight: 600;
}
.profilebtndiv span{
    font-size: 1.5rem;
    font-family: "Cairo";
    font-weight: 600;
    color: #34B7F1;
    cursor: pointer;
}

.rightprofile{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iconuser{
  font-size: 100px;
}

.iconuser svg{
  color: white;
}
.imageclass{
background-color: var(--purplecolor);
border-radius: 8px;
color: white;
font-size: 16px;
font-weight: 500;
font-family: "AvenirRegular";
padding: 10px 0px;
cursor: pointer;
}

.classnamediv1{
  width: 100%;
  padding: 5px 25px ;
  font-size: 20px;
  height: 50px;
  font-weight: 600;
  color: #fff;
  font-family: "AvenirRegular";
  background-color: red;
  border-radius: 20px 20px 0px 0px;
  /* border-radius: 8px; */
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2%;
}

@media(max-width:768px){

  .mainflexdivphotoandcontent{
    flex-direction: column-reverse;
    align-items: center;
  }
  .inputprofilemaindiv{
    width: 100%;
  }
  .select-box2{
width: 150px;
font-size: 1rem;

  }
  .select-box{
    width: 150px;
font-size: 1rem;
  }
}


@media(max-width:430px){

  .select-box2{
    width: 120px;
    font-size: 1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0px;
    height: 44px;
      }
      .select-box{
        width: 120px;
        font-size: 1rem;
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0px;
        height: 44px;
      }
      .select-box2::after{
        position: absolute;
        right: 1rem;
        top: 50%;
      }
      .select-box::after{
        position: absolute;
        right: 1rem;
        top: 50%;
      }
      .profilebtndiv h3{
        font-size: 1.1rem;
      }


.profilefootermaindiv{
  position: static;
}
}