.profiledatamain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Cairo", sans-serif;
    background-color: #FAFAFA;
    }
    .profiledatanav{
    width: 100%;
    }
    .iconuser{
        font-size: 100px;
      }
    
      .iconuser svg{
        color: white;
      }
    
    .profiledatabackicon{
    width: 100%;
    height: 110px;
    background-color: var(--blackcolor);
    position: relative;
    }
    
    .profiledatabackicon svg{
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 22px;
    color: white;
    cursor: pointer;
    }
    
    .profiledatacontainer{
    position: relative;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 30px;
    }
    
    .imagedivup{
    position: absolute;
    top: -75px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    /* this is for  */
    }
    
    .imagedivup img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    
    .usernameprofile{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    }
    
    .usernamddataetcdivs{
        width: 100%;
        /* text-align: end; */
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: end;
        gap: 10px;
        border-bottom: 1px solid #00000024;
        padding-bottom: 5px;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 600;
    }
    .profiledatacontainer div:nth-last-of-type(1){
        border-bottom: none !important;
    }
    .usernamddataetcdivs div:nth-child(1){
    color: #5D5D5D;
    }
    
    .usernamddataetcdivs div:nth-child(2){
    
    }
    
    .profiledatafooter{
    width: 100%;
    }
    
    
    
    
    
    
    
    
    