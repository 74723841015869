.ui-a-reply{
    color: #B571AC;
}
.ui-a-description{
    color: #5D5D5D;
}
.ui-a-down{
    display: flex;
    gap: 10px;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-top: 1px solid #5D5D5D30;
    font-size: 15px;
}