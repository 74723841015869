.interview-management-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: whitesmoke;
}
.i-m-navbar{
    width: 100%;
}
.i-m-footer{
    width: 100%;
}
.i-m-body{
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}
.i-m-upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}
.i-m-u-text{
    color: #B571AC;
    font-weight: 600;
}
.i-m-b-down{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.i-m-d-text{
    width: 100%;
    text-align: right;
    font-size: 17px;
    font-weight: 500;
    color: #5D5D5DC7;
    border-bottom: 2px solid #5D5D5D78;
    padding-bottom: 5px;
}
.i-m-d-input{
    width: 100%;
}
.i-m-d-input textarea{
    width: 100%;
    height: 140px;
    border: 1px solid #B571AC;
    border-radius: 10px;
    padding: 5px;
    direction: rtl;
    font-size: 15px;
}
.i-m-btn button{
    padding: 6px 25px;
    font-size: 15px;
    background-color: #B571AC;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.i-m-main{
    width: 100%;
}
.i-m-d-components{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.i-m-d-c-day{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #5D5D5DC7;
    padding-bottom: 6px;
}
.i-m-d-c-d-date{
    color: #5D5D5DC7;
}
.i-m-d-c-d-check{
    display: flex;
    gap: 5px;
}
.i-m-d-c-d-line{
    width: 100%;
    border-bottom: 1px dashed #5D5D5D78;
}
.i-m-time-container{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: end;
    flex-direction: row-reverse;
}
.i-m-t-main{
    display: flex;
    background-color: #ECD6E9;
    color: white;
    align-items: center;
    gap: 5px;
    padding: 5px;
    border-radius: 5px;
}
.i-m-t-m-time input{
    padding: 2px;
    background-color: white;
    width: 100px;
    text-align: center;
    color: black;
    border-radius: 5px;
    border: none;
}
.i-m-t-m-dropdown{
    width: 50px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.i-m-t-m-d-op:checked{
    background-color: #B571AC;
    color: white;
}

.i-m-t-end-btn{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.i-m-t-e-b-times{
    display: flex;
    gap: 10px;
}
.i-m-t-e-b-times select{
    border: 1px solid #B571AC;
    border-radius: 5px;
}
.i-m-t-end-btn button{
    padding: 8px;
    background-color: #B571AC;
    color: white;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
}