.addasectionmaindiv {
    min-height: 100vh;
    text-align: end;
    padding-block: 2rem;
    padding-inline: 2rem;
}


.maindivflexofaddasection {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 2rem;
}


.addasectionmaindiv {
    font-family: "AvenirRegular";
    font-weight: 700;
    font-size: 1.6rem;
    color: var(--purplecolor);
}

.crosicondivbutton{
    border: none;
    cursor: pointer;
    font-family: "AvenirRegular";
    font-weight: 600;
    font-size: 1.6rem;
    display: flex;
    gap: 5px;
    justify-content: end;
}

.icondivdesign{
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.249);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subsection-details{
    display: flex;
    gap: 15px;
    flex-direction: column;
}
.departmentnamedivinput {
    width: 100%;
}

.departmentnamedivinput p {
    font-family: "AvenirRegular";
    font-weight: 600;
    font-size: 1.6rem;
    color: black;
    padding-bottom: 1rem;
}

.departmentnamedivinput input {
    border-radius: 8px;
    border: 1px solid #B571AC;
    outline: none;
    padding: 1rem;
    text-align: end;
    width: 100%;
}

.addasectionoptiondiv {
    width: 40%;
}

.addasectionoptiondiv p {
    font-family: "AvenirRegular";
    font-weight: 600;
    font-size: 1.6rem;
    color: black;
    padding-bottom: 1rem;
}



.addasectionoptiondiv select {
    border-radius: 8px;
    border: 1px solid #B571AC;
    outline: none;
    padding: 0.3rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-family: "AvenirRegular";
    font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;

}

.addasectionoptiondiv option {
    padding: 0.8rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;

}


.addasectionbuttondiv {
    text-align: center;
    gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}


.addasectionbuttondiv button {
    border-radius: 5px;
    background-color: var(--purplecolor);
    color: #fff;
    cursor: pointer;
    border: none;
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    padding: 0.7rem 3rem;
}


/* 
#addasection_delete_btn{
    background-color: red;
} */
.tooglebtnandh2div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 2rem;
    justify-content: end;
    /* Ensures space between h2 and toggle button */
    width: 100%;
    margin-bottom: 20px;
    margin-top: 2rem;
}

.tooglebtnandh2div h2 {
    font-family: "Cairo", sans-serif;
    font-weight: 700;
    color: var(--graycolor);
    font-size: 1.6rem;
    margin: 0;
    /* Ensure there's no extra margin */
}

.toggle-container {
    display: flex;
    align-items: center;
}

.toggle-switch {
    width: 46px;
    height: 24px;
    background-color: #5D5D5DBA;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-switch.on {
    background-color: #4caf50;
}

.toggle-knob {
    width: 23px;
    height: 22px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
}

.toggle-switch.on .toggle-knob {
    left: 31px;
}


.plusiconandspanmaindiv{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: fit-content;
    margin: 2rem auto;
    padding: 0.5rem;
    border-bottom: 1px solid black;

}
.plusiconandspanmaindiv span{
    color: black;
    
}

.plusicondivplus{
    padding: 1rem;
    color: #fff !important;
    border-radius: 50%;
    background-color: var(--purplecolor);
}