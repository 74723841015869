.w-f-upper{
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
}
.w-f-lab-in{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 8px;
    width: 100%;
}
.w-f-lab-in input{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #B571AC;
    padding: 6px;
    direction: rtl;
}
.w-f-lab-in textarea{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #B571AC;
    padding: 6px;
    resize: none;
    direction: rtl;
}
.w-f-lab-in label{
    font-size: 14px;
    font-weight: 500;
}
.w-f-form{
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.w-f-form-container{
    padding: 20px;
}
.w-f-ending-text{
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: 16px;
    color: #B571AC;
    font-weight: 500;
}
.w-f-ending-text button{
    background-color: #B571AC;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 8px 18px;
    cursor: pointer;
}




@media only screen and (max-width: 768px){
    .w-f-upper{
        flex-wrap: wrap;
    }
}