.addadressmain{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
}

.addadressnavbar{
width: 100%;
}

.addadresscontainer{
width: 90%;
display: flex;
flex-direction: column;
gap: 15px;
justify-content: end;
}

.addadresstitle{
width: 100%;
text-align: end;
font-size: 18px;
font-weight: 600;
font-family: "avenirarabicmedium";
color: var(--purplecolor);
}

.addadresses{
    width: 100%;
    text-align: end;
    font-size: 16px;
    font-weight: 600;
    font-family: "avenirarabicmedium";
    /* color: var(--purplecolor); */
}


.addadressbutton{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
    width: 100%;
    text-align: center;
}

/* when the adresss add css */

.showadresesmain{
display: flex;
flex-direction: column;
gap: 10px;
justify-content: end;
font-weight: 600;
font-size: 16px;
}

.showaddresstitle{
color: var(--purplecolor);
}

.showadresesmain div{
text-align: end;
}


.addadressfooter{
width: 100%;
}

