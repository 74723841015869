.sa-pagination-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 5px;
}
.sa-pagination-left{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 28px;
    height: 28px;
    color: #B571AC;
    background-color: #DFE3E8;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
}
.sa-pagination-left-inactive{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 28px;
    height: 28px;
    color: #C4CDD5;
    background-color: #919EAB;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
}
.sa-pagination-active{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 35px;
    height: 35px;
    color: #B571AC;
    background-color: white;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #B571AC;
}
.sa-pagination-active1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 35px;
    height: 35px;
    color: #5D5D5D;
    background-color: #DFE3E8;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #DFE3E8;
}