.readanaklysitopdiv{
    background-color: var(--backgroundcolor);
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}

.readanalysisfooter{
    width: 100%;
}
/* Readanalysis.css */
.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

.colorred{
    color: red !important;
}
.readanalysismaindiv{
    width: 90%;
margin: auto;
    padding-block: 4rem;
}

.readanalysisbuttonmaindiv{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 2rem;
    padding-top: 4rem;
}

.simplebuttomn  {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: transparent;
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--purplecolor);
    cursor: pointer;

}

.bookmeetingcontainer{
    width: 90%;
    margin: auto;
}

.textreadanalysis{
    text-align: center;
    padding-top: 2rem;
}

.textreadanalysis h2 {
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    padding-block: 1rem;
}

.textreadanalysis h1 {
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
}


.readanalysisformmaindiv{
    text-align: end;
    padding-inline: 2rem;
}


.readanalysisformmaindiv p{
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--purplecolor);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.readanalysismaindiv input{
    width: 100%;
    border: 0.37px solid var(--graycolor);
    padding: 1.2rem;
    border-radius: 5px;
    text-align: right;
    outline: none;
    margin-block: 1rem;
    font-size: 16px;
}


.readanalysismaindiv textarea{
    width: 100%;
    border-radius: 5px;
    border: 0.37px solid var(--graycolor);
    text-align: right;
    outline: none;
    height: 150px;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
}


.readanalysisbtnsmaindiv{
    text-align: center;
}

.radioandtextmaindiv{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
    margin-top: 1rem;
}

.radioandtextmaindiv input{
    width:4% !important;
    margin-right: 1rem;
    font-size: 16px;
}

.radioandtextmaindiv p{
    /* font-family: "AvenirRegular"; */
    font-weight: 300;
    font-size: 1.5rem;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
}

.readanalysismaindiv h3{
    font-family: "AvenirRegular";
    font-weight: 400;
    padding-block: 1rem;
    font-size: 1.5rem;
}

.readanalysisfooter{
    width: 100%;
}

.uploadfileanlysis {
    position: relative;
    display: inline-block;
  }

  .custom-file-upload {
    background-color: #848383; /* Green background */
    border: none; /* Remove border */
    color: white; /* White text */
    padding: 10px 20px; /* Add some padding */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Get the element to sit inline */
    font-size: 16px; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer cursor on hover */
    border-radius: 8px; /* Rounded corners */
    transition-duration: 0.4s; /* Transition effects */
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
  }

  .Analysisrequestbtn{
    text-align: center;
  }

.Analysisrequestbtn button{
    background-color: var(--purplecolor);
    color: #fff;
    border-radius: 4px;
    padding: 1rem 2rem ;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
    margin-top: 2rem;
}

.active2 {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
}


.readanalysisTopDiv{
    background-color: #FAFAFA;
}

/* this is the css of the booking meeting */

.calendermaindiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-inline: 20px;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}


.datesselectmain{
   display: flex;
   gap: 10px;
   flex-wrap: wrap;
}

.datesselectmain button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 30px;
    background-color: white;
    border: 0.6px solid #000000;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
}

.activatetim{
     display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 30px;
    background-color: var(--purplecolor) !important;
    border: 0.6px solid var(--purplecolor) !important;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.showlinediv{
    border-bottom: 0.6px solid #848383;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    text-align: end;
    width: 100%;
    padding-bottom: 5px;
}

.datesselectmain{
    width: 100%;
    display: flex;
    justify-content: end;
}

.calendermaindiv label{
text-align: end;
width: 100%;
font-size: 16px;
font-weight: 400;
color: var(--purplecolor);
}

.textdescraption{
    width: 100%;
    text-align: end;
    padding-block: 10px;
}

.calendermaindiv textarea{
    border: 0.37px solid #5D5D5D;
    width: 100%;
    height: 120px;
    outline: none;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 14px;
    text-align: end;
    padding-right: 10px;
    padding-top: 3px;
}

.buttondivinthebotom{
width: 100%;
text-align: center;
}

.buttondivinthebotom button{
    padding: 8px 18px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--purplecolor);
    color: #fff;
    font-family: "AvenirRegular";
}

.file-names{
    color: var(--purplecolor);
    font-size: 12px;
    font-weight: 600;
}

.lasttextrupe{
font-size: 16px;
font-weight: 600;
}

.bookameetingmain{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
