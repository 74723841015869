

.mymeetingmaindiv{

    padding-inline: 2rem;
    padding-block: 2rem;
    background-color: var(--backgroundcolor);
}

.maindivofapproval{
    border: 1px solid var(--purplecolor);
    background-color: #fff;
    border-radius: 1.4rem;
    padding: 1rem;
    margin-top: 1rem;
}

.line{
    border-top: 1px solid #5D5D5D30;
    padding-block: 1rem;
    margin-top: 1rem;
}

.meetingtextmain{
    text-align: center;
}

.meetingtextmain h2{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 2.5rem;
}

.dateanddaytext{
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}


.dateanddaytext p{
    font-family: "AvenirRegular";
    font-weight: 300;
    font-size: 1.4rem;
    color: var(--purplecolor);
}


.dronlinetext h1{
    text-align: right;
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    padding-top: 1rem;
}



.showmeetingdetailtext{
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
}

.showmeetingdetailtext span{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Cairo", "Tajawal", sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    padding-block: 1rem;
}

.showmeetingdetailtext p{
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.6rem;
    padding-block: 1rem;
}


.approvaltext p{
    font-family: "AvenirRegular";
    font-weight: 800;
    font-size: 1.6rem;
    color: #5D5D5D;
    text-align: center;
}