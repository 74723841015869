.m-d-way{
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 20px;
    position: relative;
}
.m-d-w-aero{
    position: absolute;
    left: 25px;
    font-size: 25px;
}
.m-d-headbar{
    width: 100%;
    text-align: right;
    padding: 10px;
    border-bottom: 1px solid rgb(182, 182, 182);
    font-size: 15px;
    color: #5D5D5DC7;
    font-weight: 600;
}
.m-d-data-fields{
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 15px;
    padding: 10px;
    gap: 10px;
}
.m-d-d-f-branch{
    display: flex;
    gap: 10px;
}
.m-d-d-f-b-bold{
    font-weight: 500;
}
.m-d-long-text{
    padding: 10px;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
}
.m-d-list-things{
    width: 100%;
    padding: 10px;
}
.m-d-list-parent{
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    padding-inline: 10px;
    direction: rtl;
}
.m-d-mid-head-text{
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid rgb(182, 182, 182);
}
.m-d-zoom-details-margin{
    padding-top: 15px;
    font-size: 15px;
    padding-inline: 10px;
    font-family: 'Times New Roman', Times, serif;
}
.meeting-details-body-container{
    padding: 20px;
}
.m-d-end-btns{
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}
.m-d-cancel-btn button{
    padding: 8px 15px;
    border-radius: 5px;
    color: white;
    border: none;
    background-color: red;
    font-size: 15px;
    cursor: pointer;
}
.m-d-postpone-btn button{
    padding: 8px 15px;
    border-radius: 5px;
    color: white;
    border: none;
    background-color: rgb(241, 241, 0);
    font-size: 15px;
    cursor: pointer;
}
.m-d-ending-text-mid{
    text-align: center;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
}
.m-d-bg-black-shadow{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
}
.m-d-c-p-ttext{
    padding: 20px 30px;
}
.m-d-cancel-popup{
    background-color: white;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    z-index: 9999999999;
    margin: 20px;
}
.m-d-c-p-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.226);
    z-index: 10000;
}
.m-d-c-p-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid rgb(209, 209, 209);
}
.m-d-c-p-btn button{
    padding: 8px 15px;
    border: none;
    color: white;
    background-color: red;
    border-radius: 5px;
}
.m-d-c-p-btn1{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid rgb(209, 209, 209);
}
.m-d-c-p-btn1 button{
    padding: 8px 15px;
    border: none;
    color: white;
    background-color: #B571AC;
    border-radius: 5px;
}