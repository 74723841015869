

.Myanalytics{
    height: 100vh;
}

.meetingdescriptiontext{
    text-align: right;
    padding-block: 1rem;
}

.meetingdescriptiontext p{
    color: #5D5D5D;
    font-family: "AvenirRegular";
    font-weight: 400;
    font-size: 1.5rem;
}