.secondformstepmain{
display: flex;
flex-direction: column;
gap: 10px;
justify-content: end;
font-family: "AvenirRegular";
}
.bookameetingmain {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }

  .bookameetingmain.fadeOut {
    opacity: 0;
  }
.book-a-meeting-down-text{
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding-top: 15px;
}
.secondfromtitle{
text-align: end;
font-size: 18px;
font-weight: 600;
color: var(--purplecolor);
}

.secondformdetails{
text-align: end;
font-size: 16px;
font-weight: 600;
}

.secondformbutton{
width: 100%;
}

.secondformbutton button {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
}


.checkboxaddedmain{
    flex-direction: row !important;
    align-items: center !important;
}

.disablebuttondiv{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    /* border: 1px solid var(--purplecolor); */
    /* background-color: var(--purplecolor); */
    background-color: gray;
    border: none;
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.button-active {
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    outline: none;
    border-radius: 1rem;
    border: 1px solid var(--purplecolor);
    background-color: var(--purplecolor);
    font-family: "AvenirRegular";
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--whitecolor);
    cursor: pointer;
    width: 100%;
    text-align: center;
}


/* this is the css of the progress bar  */

.progressbarmain{
display: flex;
justify-content: center;
align-items: center;
}

.firststep{
width: 30px;
height: 30px;
border: 1px solid var(--purplecolor);
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
}
.activefirst{
    background-color: var(--purplecolor) !important;
}

.activefirst svg{
    color: white !important;
    font-size: 14px;
}

.firststep svg{
    color: var(--purplecolor);
    font-size: 14px;
}

.firststepline{
width: 25%;
height: 1px;
background-color: var(--purplecolor);
}

.secondstep{
    width: 30px;
    height: 30px;
    border: 1px solid #A5A5A5;
    border-radius: 50%;
    display: flex;
justify-content: center;
align-items: center;
}

.secondstep svg{
    color: #A5A5A5;
    font-size: 14px;
}

.activesecond{
    border: 1px solid var(--purplecolor) !important;
}

.activesecond svg{
    color: var(--purplecolor) !important;
}

.activesecondline {
    background-color: var(--purplecolor) !important;
}

.secondstepline{
    width: 25%;
    height: 1px;
    background-color: #A5A5A5;
}

.thirdstep{
    width: 30px;
    height: 30px;
    border: 1px solid #A5A5A5;
    border-radius: 50%;
    display: flex;
justify-content: center;
align-items: center;
}
.thirdstep svg{
    color: #A5A5A5;
    font-size: 14px;
}

