.communationmin{
display: flex;
justify-content: center;
align-items: center;
gap: 30px;
flex-direction: column;
}
.communationnvar{
width: 100%;
}

.communationcontainer{
    
    height: 90vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.navtopbar{
display: flex;
justify-content: space-between;
align-items: center;
}
.backiconsdiv{
font-size: 20px;
font-weight: 600;
cursor: pointer;
}
.maintitlediv{
font-size: 18px;
font-weight: 600;
color: var(--purplecolor);
}


.toggle-container{
    justify-content: end;
    display: flex;
    align-items: center;
}

.toggle-label{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}

.toggle-switch.on{
background-color: var(--purplecolor) !important;
}

.toggle-switch.on .toggle-knob {
    left: 22px;
}

.toggle-switch.off{
    
}

.input-container{
display: flex;
gap: 10px;
flex-direction: column;
}

.input-container label{
    font-size: 14px;
    font-weight: 600;
    text-align: end;
}

.-container input{
    font-size: 14px;
    font-weight: 600;
    text-align: start;
padding-right: 10px;
width: 100%;
height: 35px;
border: 1px solid var(--purplecolor);
border-radius: 8px;
outline: none;
}

.submit-button-container{
width: 100%;
text-align: center;
}

.submit-button-container button{
    font-family: "AvenirRegular";
    font-size: 16px;
    background-color: var(--purplecolor);
    border: none;
    padding: 8px 14px;
    border-radius: 12px;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
}

.input-container .flag-dropdown {
    position: absolute;
    left: 2px;
    padding: 0px;
    top: 1px;
    height: 33px !important;
}

.inputerrorclass .react-tel-input .form-control{
    border: 1px solid red;
}
.input-container .react-tel-input .form-control{
    border: 1px solid var(--purplecolor);
}

.communationfooter{
width: 100%;
}
