.uploadbannermaindiv{
width: 100%;
display: flex;
flex-direction: column;
gap: 30px;
justify-content: center;
align-items: center;
}

.uplaodbannernavbar{
width: 100%;
}

.bannercontaierdiv{
width: 90%;
display: flex;
flex-direction: column;
gap: 20px;
justify-content: center;
align-items: center;
}

.toptitlediv{
display: flex;
justify-content: space-between;
align-items: center;
font-size: 18px;
font-weight: 600;
width: 100%;
}

.backicondiv{
cursor: pointer;
}

.titledivbaner{
color: var(--purplecolor);
}


.buttonsdiv{
display: flex;
gap: 10px;
font-size: 14px;
font-weight: 600;
flex-direction: row-reverse;
}
.buttonsdiv button{
    padding: 8px 16px;
    border:1px solid var(--purplecolor);
    background-color: transparent;
    border-radius: 6px;
    color: var(--purplecolor);
}

.uploadimagediv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}
.uploadimagediv img{
    width: 100%;
    height: 40vh;
    object-fit: contain;
}

.uploadimagediv button{
    padding: 8px 16px;
    border:none;
    background-color: var(--purplecolor);
    border-radius: 6px;
    color: white;
    font-family: 'Cairo';
    cursor: pointer;
}

.active-button{
    background-color: var(--purplecolor) !important;
    color: white !important;
}

.formdivmain{
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: end;
    width: 100%;
}

.formdivmain label{
    text-align: end;
    font-size: 14px;
    font-weight: 600;
    color: #5D5D5D;
}

.formdivmain input{
text-align: end;
height: 35px;
width: 100%;
border-radius: 6px;
border: 1px solid var(--purplecolor);
outline: none;
font-size: 14px;
    padding-right: 10px;
}

.formdivmain input::placeholder{

}

.formdivmain textarea{
    text-align: end;
    height: 140px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--purplecolor);
    outline: none;
    font-size: 14px;
        padding-right: 10px;
}

.submitmaindiv{
    display: flex;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}

.submitmaindiv button{
    padding: 8px 16px;
    border:none;
    background-color: var(--purplecolor);
    border-radius: 6px;
    color: white;
    font-family: 'Cairo';
    cursor: pointer;
}


.bannerfooterdiv{
    width: 100%;
}




