.webinar-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: whitesmoke;
    padding-inline: 10px;text-align: center;
}
.w-head-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
    text-align: center;
}
.w-h-c-text{
    font-weight: 500;
    font-size: 16px;
    color:#B571AC;
}
.w-h-c-head{
    font-weight: 600;
}
.w-middle-container{
    padding-left: 45px;
    padding-right: 45px;
}
.w-middle-flex{
    display: flex;
    padding-top: 15px;
    flex-direction: row-reverse;
    gap: 20px;
}
.w-upper-head{
    font-size: 18px;
    color: #B571AC;
}
.w-upper-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 15px;
    text-align: right;
}
.w-middle-head{
    font-size: 18px;
    color: #B571AC;
    width: 70px;
}
.w-middle-text{
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 15px;
    border-bottom: 2px solid #5d5d5d;
    text-align: right;
}
.w-lower-text{
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 4px;
    text-align: right;
}
.input-heading{
    color: #48B865;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 70px;
}
