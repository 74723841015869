.paymenthismain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #FAFAFA;
}
.paymenthisnav {
    width: 100%;
}
.paymenthiscontainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.upper-btn-container{
    display: flex;
    justify-content: end;
    gap: 15px;
    padding: 10px;
    width: 100%;
    flex-direction: row-reverse;
    overflow: scroll;
}
.upper-btn-container::-webkit-scrollbar {
    display: none; /* Adjust the height of the scrollbar for horizontal scrolling */
  }
.upper-btn-red-active button{
    padding: 10px 16px;
    border: none;
    background-color: #B571AC;
    width: 150px;
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}
.upper-btn-red-inactive button{
    padding: 10px 16px;
    border: none;
    width: 150px;
    background-color: #FFFFFF;
    color: #B571AC;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}
.upper-btn-yellow-active button{
    padding: 10px 16px;
    width: 150px;
    border: none;
    background-color: #B571AC;
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}
.upper-btn-yellow-inactive button{
    padding: 10px 16px;
    border: none;
    background-color: #FFFFFF;
    width: 150px;
    color: #B571AC;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}
.upper-btn-green-active button{
    padding: 10px 16px;
    border: none;
    background-color: #B571AC;
    color: #FFFFFF;
    width: 150px;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}
.upper-btn-green-inactive button{
    padding: 10px 16px;
    border: none;
    background-color: #FFFFFF;
    color: #B571AC;
    width: 150px;
    font-size: 18px;
    border-radius: 23px;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
    font-weight: 600;
}





.paymenthistitle {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.paymenttopmain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid var(--purplecolor);
    padding: 5px;
    margin-top: 10px;
    gap: 10px;
}

.payenttopright div:nth-of-type(2) {
    text-align: end;
    display: flex;
    gap: 20px;
    justify-content: end;
    color: #5D5D5D;
    font-size: 14px;
}

.payenttopright div:nth-of-type(2) span {}

.payenttopright {
    font-size: 16px;
    font-weight: 600;
    text-align: end;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.paymenttopleft {
    font-size: 16px;
    font-weight: 600;
}


.paymenthisfooter {
    width: 100%;
}

/* this is the css fo the admain addations */

.paymentnavigationmain{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding: 10px;
    flex-direction: row-reverse;
}
.payactive1{
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    background: #B571AC;
    color: white;
    box-shadow: 0px 0px 4px 0px #00000040;
cursor: pointer;
}

.payactive {
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    background: #B571AC;
    color: white;
    box-shadow: 0px 0px 4px 0px #00000040;
cursor: pointer;
}
.payinactive{
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    background: white;
    color: #B571AC;
    box-shadow: 0px 0px 4px 0px #00000040;
cursor: pointer;
}
.payinactive1{
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    background: white;
    color: #B571AC;
    box-shadow: 0px 0px 4px 0px #00000040;
cursor: pointer;
}

.nopaymentmain{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 65vh;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border-radius: 12px;
}




