
.meetingmaindiv{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;
background-color: #FAFAFA;
}
.meetingnavbar{
width: 100%;
}

.meetingfooterdiv{
width: 100%;
}


.meetingnotifymaindiv{
width: 90%;
display: flex;
flex-direction: column;
gap: 15px;
text-align: end;
}

.buttonsmaindiv{
    padding-top: 10px;
display: flex;
gap: 10px;
justify-content: end;
flex-direction: row-reverse;
width: 100%;
align-items: center;
height: 80px; /* Adjust the height as needed */
  overflow-x: scroll;
}


.buttonsmaindiv::-webkit-scrollbar {
    display: none; /* Adjust the height of the scrollbar for horizontal scrolling */
  }

.buttonsmaindiv div{
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #B571AC;
}

.subbuttonsmaindiv{
    padding-top: 10px;
    display: flex;
gap: 10px;
justify-content: end;
flex-direction: row-reverse;

}

.subbuttonsmaindiv div{
    background-color: #FFFFFF;
    color: #B571AC;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    border: 1px solid #B571AC;
    font-weight: 600;
}

.activemeetings{
    background-color: #B571AC !important;
    color: white !important;
}

.addmeetingclass{
    flex-direction: column;
    gap: 7px;
}

.greensubbuttonsmain{
    padding-top: 10px;
    display: flex;
gap: 10px;
justify-content: end;
flex-direction: row-reverse;

}

.greensubbuttonsmain div{
    background-color: #FFFFFF;
    color: #B571AC;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.activemeetingsend{
    background-color: #B571AC !important;
    color: #FFFFFF !important;
}

.yellowsubbuttonsmain{
    padding-top: 10px;
    display: flex;
gap: 10px;
justify-content: end;
flex-direction: row-reverse;
}

.yellowsubbuttonsmain div{
    background-color: #C9FFD7;
    color: #42AC5D;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.calendar-container{
    margin: auto;
}

.addcolorclass{
    background-color: #B571AC;
}

.addcolorbrown{
    background-color: #B571AC;
}

@media (max-width: 430px){
    .buttonsmaindiv{
        gap: 5px;
    }
    .buttonsmaindiv div{
        font-size: 14px;
        white-space: nowrap;
        padding: 8px 12px;
    }

    .subbuttonsmaindiv div{
        font-size: 14px;
    }

    .greensubbuttonsmain div{
        font-size: 14px;
    }
    .yellowsubbuttonsmain div {
        font-size: 14px;
        white-space: nowrap;
    }
}

.react-calendar__navigation button{
    color: var(--purplecolor);
}

.react-calendar__month-view__weekdays__weekday abbr {
text-decoration: none !important;
}
.react-calendar{
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px !important;
    padding: 10px !important;
}
.react-calendar__tile--active{
    border-radius: 8px !important;
    background-color: #2F7BD5 !important;
}

.react-calendar__tile--now{
    border-radius: 8px !important;
    background-color: transparent !important;
    font-weight: 900 !important;
    color: black !important;
}

.react-calendar button:enabled:hover{
    background-color: #476a94 !important;
    border-radius: 8px;
}
/* .calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .react-calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .react-calendar__navigation button {
    background: none;
    border: none;
    color: #555;
    font-size: 1.2em;
  }

  .react-calendar__navigation button:disabled {
    color: #ddd;
  }

  .react-calendar__navigation__label {
    font-weight: bold;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--now {
    background: #2f7bd5;
    color: white;
    border-radius: 50%;
  }

  .react-calendar__tile--now {
    background: #74a9d8;
    color: white;
  }

  .react-calendar__month-view__weekdays {
    color: #555;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .highlight {
    background-color: #2f7bd5 !important;
    color: white;
    border-radius: 50%;
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 0;
    border-radius: 50%;
  }
   */

