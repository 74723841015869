.mainerrormain{
width: 100%;
display: flex;
flex-direction: column;
gap: 30px;
background-color: #FAFAFA;
justify-content: center;
align-items: center;
}


.errorscreennav{
width: 100%;
}

.errorscreencontainer{
height: 65vh;
width: 90%;
display: flex;
flex-direction: column;
gap: 10px;
justify-content: center;
align-items: center;
}
.errorscreencontainer div:nth-child(1){
font-size: 50px;
font-weight: 900;
color: var(--purplecolor);
}


.errorscreencontainer div:nth-child(2){
    font-size: 18px;
    font-weight: 600;
}

.errorscreenfooter{
width: 100%;
}


